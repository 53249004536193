import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import timeZone from 'moment-timezone';
import moment from 'moment/moment.js';
import React, { useState } from 'react';
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import SubtleBadge from '../../../../components/common/SubtleBadge';
import Message from '../../../../components/Message';
import { usePayOrdersMutation } from '../../../../slices/ordersApiSlice';

const getCircleStackIcon = (icon, transform) => (
	<span className='fa-stack ms-n1 me-3'>
		<FontAwesomeIcon icon='circle' className='text-200 fa-stack-2x' />
		<FontAwesomeIcon icon={icon} transform={transform ?? ''} className='text-primary fa-stack-1x' inverse />
	</span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
	<Flex className='mt-3'>
		{getCircleStackIcon(icon)}
		<div className='flex-1'>
			<h6>{heading}</h6>
			{children || <p className='mb-0 text-justify'>{content}</p>}
		</div>
	</Flex>
);

const AccountsReceivableModal = ({ setIsOpenPendingModal, isOpenPendingModal, modalPendingContent, setModalPendingContent, refetchAROrders, isAROrderFetching }) => {
	const { isDark } = useSelector((state) => state.config);
	const { userInfo } = useSelector((state) => state.auth);
	const [currentButtonValue, setCurrentButtonValue] = useState('');
	const [payOrder, { isLoading: loadingPay }] = usePayOrdersMutation();

	const handleClose = () => {
		setIsOpenPendingModal(!isOpenPendingModal);
		setModalPendingContent({});
		refetchAROrders();
	};

	const onPayOrder = async (accountsReceivable, title, orderOId, orderId) => {
		await payOrder({ orderOId, details: { payAccountsReceivable: accountsReceivable, title } });
		toast.success(orderId + '订单' + title + '款项已核销!');
		handleClose();
	};
	const { _id, orderId, customerInfo, orderNotes, scheduleNodes, totalPrice, approvalStatus, paymentStatus, percentPayment, prePaymentPercent } = modalPendingContent;

	return (
		<Modal show={isOpenPendingModal} onHide={handleClose} contentClassName='border' centered size='lg'>
			<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className='bg-body-tertiary px-x1 border-bottom-0'>
				<Modal.Title>
					<Flex>
						<h5 className='mb-0'>{orderId}</h5>
						{approvalStatus === 'inProcessOfApproval' && (
							<Badge bg='danger' className='ms-2 fs-10'>
								待审批
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayPlaceOrder' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收预付款
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayDelivery' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收发货款
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayAccept' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收验收款
							</Badge>
						)}
					</Flex>

					{customerInfo?.customerName && (
						<p className='mb-0 fs-10 mt-1 fw-normal'>
							客户名称：<a href='#!'>{customerInfo.customerName}</a>
						</p>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='px-x1 pb-x1 pt-1 fs-10'>
				{customerInfo?.staffName && (
					<EventModalMediaContent icon='align-left' heading='客户信息'>
						<Flex justifyContent=''>
							<p className='mb-0 fs-10 mt-1 fw-normal me-3'>
								联系人：<a href='#!'>{customerInfo.staffName}</a>
							</p>
							{customerInfo?.phone && (
								<p className='mb-0 fs-10 mt-1 fw-normal'>
									电话：<a href='#!'>{customerInfo.phone}</a>
								</p>
							)}
						</Flex>
					</EventModalMediaContent>
				)}

				{totalPrice && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单总价' content={`¥${totalPrice}`} />}
				{percentPayment > 0 && (
					<>
						<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付款'>
							<span className='me-2'>¥{percentPayment}</span>
							<SubtleBadge>{prePaymentPercent}%</SubtleBadge>
						</EventModalMediaContent>
					</>
				)}

				{scheduleNodes && scheduleNodes.length !== 0 && (
					<EventModalMediaContent icon='clock' heading='尾款计划'>
						<ul className='list-unstyled timeline mb-0'>
							{scheduleNodes.map((schedule, index) => (
								<li key={index} className='mb-1'>
									<span className='me-3'>
										{schedule.title}后 {schedule.paymentDays} 天
									</span>
									<span className='me-3'>应收账款：¥{schedule.paymentAmount}</span>
									<SubtleBadge>{schedule.paymentPercent}%</SubtleBadge>
								</li>
							))}
						</ul>
					</EventModalMediaContent>
				)}
				{modalPendingContent.accountsReceivables && modalPendingContent.accountsReceivables.length !== 0 && (
					<EventModalMediaContent icon='clock' heading='应收账款'>
						<ul className='list-unstyled timeline mb-0'>
							{modalPendingContent.accountsReceivables.map((ar) => {
								const calculatedDays = timeZone.tz(moment(ar.expire_time), 'Asia/Shanghai').diff(timeZone.tz(moment(), 'Asia/Shanghai'), 'days');

								return (
									<li className='mb-1'>
										<span className={classNames('me-3', !ar.isPaid && 'fw-semibold')}>{ar.title}款</span>
										<span className={classNames('me-3', !ar.isPaid && 'fw-semibold')}>¥{ar.accountsReceivable}</span>
										{ar.isPaid ? (
											<>
												<SubtleBadge className='me-2' bg='success'>
													已收款
												</SubtleBadge>
												<FontAwesomeIcon icon='check' className='text-success' />
												<span className='text-success-emphasis fw-semibold fs-11 ms-2'>
													{ar.title}款已支付在 {timeZone.tz(moment(ar.paidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
												</span>
											</>
										) : (
											<>
												<SubtleBadge className='me-2' bg='warning'>
													待确认
												</SubtleBadge>
												<FontAwesomeIcon icon='fa-calendar-day' className={classNames(calculatedDays < 7 ? 'text-danger' : 'text-warning')} />
												<span className={classNames('ms-2 fw-semibold', calculatedDays < 7 ? 'text-danger' : 'text-warning')}>
													付款到期日期 {timeZone.tz(moment(ar.expire_time), 'Asia/Shanghai').format('YYYY-MM-DD')}
												</span>
												<span className={classNames('ms-2 fw-semibold', calculatedDays < 7 ? 'text-danger' : 'text-warning')}>
													{calculatedDays < 0 ? (
														<>
															超期 {calculatedDays} 天 <FontAwesomeIcon icon='fa-circle-exclamation' beat className='text-danger ms-2' />
														</>
													) : calculatedDays === 0 ? (
														<>
															今日到期，请及时催收款项!
															<FontAwesomeIcon icon='fa-triangle-exclamation' beat className='text-danger ms-2' />
														</>
													) : (
														`剩余 ${timeZone.tz(moment(ar.expire_time), 'Asia/Shanghai').diff(timeZone.tz(moment(), 'Asia/Shanghai'), 'days')} 天`
													)}
												</span>
											</>
										)}
										{userInfo?.role === 'finance' && !ar.isPaid && (
											<Button
												size='sm'
												className='ms-2 mb-2 fs-11 fw-semibold'
												onClick={(e) => {
													setCurrentButtonValue(e.currentTarget.value);
													onPayOrder(ar.accountsReceivable, ar.title, modalPendingContent._id, modalPendingContent.orderId);
												}}
												value={ar.title}
											>
												<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
												确认已收{ar.title}款
											</Button>
										)}
										{loadingPay && ar.title === currentButtonValue && <Spinner size='sm' className='ms-2 text-primary' />}
									</li>
								);
							})}
						</ul>
					</EventModalMediaContent>
				)}

				{orderNotes && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单备注' content={orderNotes} />}
			</Modal.Body>
			{isAROrderFetching && <Message>正在更新应收账款...</Message>}

			<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
				<>
					<Button as={Link} to={`/order/detail/${_id}`} variant='falcon-primary' size='sm'>
						<span>查看更多细节</span>
						<FontAwesomeIcon icon='angle-right' className='fs-11 ms-1' />
					</Button>
				</>
			</Modal.Footer>
		</Modal>
	);
};

export default AccountsReceivableModal;
