import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
	useGetPendingOrderForAssistantQuery,
	useGetPendingOrderForBuyerQuery,
	useGetPendingOrderForFinanceQuery,
	useGetPendingOrderForManagerQuery,
	useGetPendingOrderForSaleQuery,
} from '../slices/dashboardApiSlice';
import { useSelector } from 'react-redux';

export const OrderContext = createContext({ order: [] });

const OrderProvider = ({ children }) => {
	const { userInfo } = useSelector((state) => state.auth);

	const [skipLoadManager] = useState(true);
	const [skipLoadFinance] = useState(true);
	const [skipLoadBuyer] = useState(true);
	const [skipLoadSale] = useState(true);
	const [skipLoadAssistant] = useState(true);

	const {
		data: orderPendingForManager = [],
		isLoading: loadingManagerPending,
		error: loadingManagerPendingError,
		isUninitialized: uninitializedManager,
		refetch: refetchManagerPending,
	} = useGetPendingOrderForManagerQuery(undefined, {
		skip: userInfo?.role === 'manager' ? !skipLoadManager : skipLoadManager,
	});

	const {
		data: orderPendingForFinance = [],
		isLoading: loadingFinancePending,
		error: loadingFinancePendingError,
		isUninitialized: uninitializedFinance,
		refetch: refetchFinancePending,
	} = useGetPendingOrderForFinanceQuery(undefined, {
		skip: userInfo?.role === 'finance' ? !skipLoadFinance : skipLoadFinance,
	});
	const {
		data: orderPendingForBuyer = [],
		isLoading: loadingBuyerPending,
		error: loadingBuyerPendingError,
		isUninitialized: uninitializedBuyer,
		refetch: refetchBuyerPending,
	} = useGetPendingOrderForBuyerQuery(undefined, {
		skip: userInfo?.role === 'buyer' ? !skipLoadBuyer : skipLoadBuyer,
	});
	const {
		data: orderPendingForSale = [],
		isLoading: loadingSalePending,
		error: loadingSalePendingError,
		isUninitialized: uninitializedSale,
		refetch: refetchSalePending,
	} = useGetPendingOrderForSaleQuery(undefined, {
		skip: ['sale', 'user'].includes(userInfo?.role) ? !skipLoadSale : skipLoadSale,
	});
	const {
		data: orderPendingForAssistant = [],
		isLoading: loadingAssistantPending,
		error: loadingAssistantPendingError,
		isUninitialized: uninitializedAssistant,
		refetch: refetchAssistantPending,
	} = useGetPendingOrderForAssistantQuery(undefined, {
		skip: userInfo?.role === 'assistant' ? !skipLoadAssistant : skipLoadAssistant,
	});

	useEffect(() => {
		if (!uninitializedManager) {
			refetchManagerPending();
		}
		if (!uninitializedSale) {
			refetchSalePending();
		}
		if (!uninitializedAssistant) {
			refetchAssistantPending();
		}
		if (!uninitializedFinance) {
			refetchFinancePending();
		}
		if (!uninitializedBuyer) {
			refetchBuyerPending();
		}
	}, [
		refetchAssistantPending,
		refetchBuyerPending,
		refetchFinancePending,
		refetchManagerPending,
		refetchSalePending,
		skipLoadAssistant,
		skipLoadBuyer,
		skipLoadFinance,
		skipLoadSale,
		uninitializedAssistant,
		uninitializedBuyer,
		uninitializedFinance,
		uninitializedManager,
		uninitializedSale,
	]);

	return (
		<OrderContext.Provider
			value={{
				orderPendingForManager,
				orderPendingForFinance,
				orderPendingForBuyer,
				orderPendingForAssistant,
				orderPendingForSale,
				loadingManagerPending,
				loadingFinancePending,
				loadingBuyerPending,
				loadingAssistantPending,
				loadingSalePending,
				loadingManagerPendingError,
				loadingAssistantPendingError,
				loadingFinancePendingError,
				loadingBuyerPendingError,
				loadingSalePendingError,
				refetchAssistantPending,
				refetchBuyerPending,
				refetchFinancePending,
				refetchManagerPending,
				refetchSalePending,
				skipLoadManager,
				skipLoadAssistant,
				skipLoadBuyer,
				skipLoadFinance,
				skipLoadSale,
				uninitializedAssistant,
				uninitializedBuyer,
				uninitializedFinance,
				uninitializedManager,
				uninitializedSale,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};

OrderProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useOrderContext = () => useContext(OrderContext);

export default OrderProvider;
