import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import SubtleBadge from '../../../../components/common/SubtleBadge';
import { useUpdateOrderApprovalByIdMutation } from '../../../../slices/approvalsApiSlice';
import { useNotifyOrderApprovedToApplicantMutation } from '../../../../slices/notificationsApiSlice';
import { useApproveOrderMutation } from '../../../../slices/ordersApiSlice';

const getCircleStackIcon = (icon, transform) => (
	<span className='fa-stack ms-n1 me-3'>
		<FontAwesomeIcon icon='circle' className='text-200 fa-stack-2x' />
		<FontAwesomeIcon icon={icon} transform={transform ?? ''} className='text-primary fa-stack-1x' inverse />
	</span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
	<Flex className='mt-3'>
		{getCircleStackIcon(icon)}
		<div className='flex-1'>
			<h6>{heading}</h6>
			{children || <p className='mb-0 text-justify'>{content}</p>}
		</div>
	</Flex>
);

const ApproveModal = ({ setIsOpenPendingModal, isOpenPendingModal, modalPendingContent, refetchMyApprovals, refetchAROrders }) => {
	const { isDark } = useSelector((state) => state.config);

	const [updateOrderApprovalStatus] = useUpdateOrderApprovalByIdMutation();

	const [approveOrder, { isLoading: approvingOrder, isSuccess: approveSuccess }] = useApproveOrderMutation();

	const [notifyOrderApprovedToApplicant] = useNotifyOrderApprovedToApplicantMutation();
	const [currentBtn, setCurrentBtn] = useState('');
	const handleClose = () => {
		setIsOpenPendingModal(!isOpenPendingModal);
		refetchMyApprovals();
		refetchAROrders();
	};

	const handlePass = async (e) => {
		setCurrentBtn(e.currentTarget.value);
		try {
			const approved_res = await updateOrderApprovalStatus({
				_id: approval._id,
				status: 'approved',
			}).unwrap();

			const { approver, applicant, assistant, detail, _id, status } = approved_res;

			const approve_order_res = await approveOrder({
				orderId: detail.orderOID,
				approvalResult: status,
			}).unwrap();

			// notify applicant
			await notifyOrderApprovedToApplicant({
				toUser: applicant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}审批通过 ✅`, detail, approvalId: _id },
			});

			// notify assistant
			await notifyOrderApprovedToApplicant({
				toUser: assistant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}审批通过 ✅`, detail, approvalId: _id },
			});

			toast.success('审批通过');

			handleClose();
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};

	const handleUnagree = async (e) => {
		setCurrentBtn(e.currentTarget.value);
		try {
			const approved_res = await updateOrderApprovalStatus({
				_id: approval._id,
				status: 'reject',
			}).unwrap();

			const { approver, applicant, assistant, detail, _id, status } = approved_res;

			const approve_order_res = await approveOrder({
				orderId: detail.orderOID,
				approvalResult: status,
			}).unwrap();

			await notifyOrderApprovedToApplicant({
				toUser: applicant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}被驳回 🚫`, detail, approvalId: _id },
			});

			await notifyOrderApprovedToApplicant({
				toUser: assistant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}被驳回 🚫`, detail, approvalId: _id },
			});
			toast.success('申请已驳回');
			refetchMyApprovals();
			handleClose();
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};
	const { orderId, customerInfo, orderNotes, scheduleNodes, approval, totalPrice, approvalStatus, percentPayment, prePaymentPercent } = modalPendingContent;

	return (
		<Modal show={isOpenPendingModal} onHide={handleClose} contentClassName='border' centered size='lg'>
			<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className='bg-body-tertiary px-x1 border-bottom-0'>
				<Modal.Title>
					<Flex>
						<h5 className='mb-0'>{orderId}</h5>
						{approvalStatus === 'inProcessOfApproval' && (
							<Badge bg='danger' className='ms-2 fs-10'>
								待审批
							</Badge>
						)}
					</Flex>

					{customerInfo?.customerName && (
						<p className='mb-0 fs-10 mt-1 fw-normal'>
							客户名称：<a href='#!'>{customerInfo.customerName}</a>
						</p>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='px-x1 pb-x1 pt-1 fs-10'>
				{customerInfo?.staffName && (
					<EventModalMediaContent icon='align-left' heading='客户信息'>
						<Flex justifyContent=''>
							<p className='mb-0 fs-10 mt-1 fw-normal me-3'>
								联系人：<a href='#!'>{customerInfo.staffName}</a>
							</p>
							{customerInfo?.phone && (
								<p className='mb-0 fs-10 mt-1 fw-normal'>
									电话：<a href='#!'>{customerInfo.phone}</a>
								</p>
							)}
						</Flex>
					</EventModalMediaContent>
				)}

				{totalPrice && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单总价' content={`¥${totalPrice}`} />}
				{percentPayment > 0 && (
					<>
						<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付款'>
							<span className='me-2'>¥{percentPayment}</span>
							<SubtleBadge>{prePaymentPercent}%</SubtleBadge>
						</EventModalMediaContent>
					</>
				)}
				{scheduleNodes && scheduleNodes.length !== 0 && (
					<EventModalMediaContent icon='clock' heading='尾款计划'>
						<ul className='list-unstyled timeline mb-0'>
							{scheduleNodes.map((schedule, index) => (
								<li key={index} className='mb-1'>
									<span className='me-3'>
										{schedule.title}后 {schedule.paymentDays} 天
									</span>
									<span className='me-3'>应收账款：¥{schedule.paymentAmount}</span>
									<SubtleBadge>{schedule.paymentPercent}%</SubtleBadge>
								</li>
							))}
						</ul>
					</EventModalMediaContent>
				)}
				{orderNotes && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单备注' content={orderNotes} />}
			</Modal.Body>
			<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
				{approvalStatus === 'inProcessOfApproval' && (
					<>
						<Button variant='falcon-success' size='sm' value='passBtn' onClick={(e) => handlePass(e)} hidden={approveSuccess}>
							{approvingOrder && currentBtn === 'passBtn' ? (
								<Spinner size='sm' />
							) : (
								<>
									<FontAwesomeIcon icon='pencil-alt' className='fs-11 me-2' />
									<span>同意</span>
								</>
							)}
						</Button>
						<Button variant='falcon-danger' size='sm' value='unPassBtn' onClick={(e) => handleUnagree(e)} hidden={approveSuccess}>
							{approvingOrder && currentBtn === 'unPassBtn' ? (
								<Spinner size='sm' />
							) : (
								<>
									<FontAwesomeIcon icon='pencil-alt' className='fs-11 me-2' />
									<span>不同意</span>
								</>
							)}
						</Button>
						<Button as={Link} to={`/approval/order/${approval?.detail.orderOID}/detail/${approval?._id}`} variant='falcon-primary' size='sm'>
							<span>查看更多细节</span>
							<FontAwesomeIcon icon='angle-right' className='fs-11 ms-1' />
						</Button>
					</>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default ApproveModal;
