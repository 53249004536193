import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Badge, Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../../components/common/advance-table/AdvanceTableFooter';
import CardDropdown from '../../../components/common/CardDropdown';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import useAdvanceTable from '../../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../../providers/AdvanceTableProvider';
import PrePaymentModal from './modal/PrePaymentModal';

const PrePaymentOrderPendings = ({ data, isLoading, isError, refetch, isFetching }) => {
	const [isOpenPendingModal, setIsOpenPendingModal] = useState(false);
	const [modalPendingContent, setModalPendingContent] = useState({});

	const columns = useMemo(
		() => [
			{
				accessorKey: 'orderId',
				header: '订单编号',
				meta: {
					headerProps: { className: 'sticky-column text-900' },
					cellProps: {
						className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2',
					},
				},
				cell: ({ row: { original } }) => {
					const { orderId, deliveryStatus } = original;
					let bg = '';
					let icon = '';
					let text = '';
					switch (deliveryStatus) {
						case 'waitingDelivery':
							bg = 'danger';
							icon = 'coins';
							text = '待确认';
							break;
						default:
							break;
					}
					return (
						<>
							<div className='d-flex justify-content-center align-items-center'>
								<div>
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: 'fixed' }} id='basePriceTooltip'>
												<FontAwesomeIcon icon={icon} className={classNames('me-2', `text-${bg}`)} />
												{text}
											</Tooltip>
										}
									>
										<FontAwesomeIcon icon='circle' className={classNames('me-2 fs-11', `text-${bg}`)} />
									</OverlayTrigger>
								</div>

								<Link
									onClick={(e) => {
										setModalPendingContent(original);
										setIsOpenPendingModal(true);
									}}
									className='text-danger'
								>
									{orderId}
								</Link>
							</div>
						</>
					);
				},
			},
			{
				accessorKey: 'customerName',
				header: '客户名称',
				meta: {
					headerProps: { className: 'pe-3 text-900 text-start' },
					cellProps: { className: 'text-start' },
				},
				cell: ({ row: { original } }) => {
					const { customerInfo } = original;
					return <span className='text-danger'>{customerInfo.customerName}</span>;
				},
			},
			{
				accessorKey: 'approvalStatus',
				header: '支付状态',
				meta: {
					headerProps: { className: 'pe-3 text-900' },
				},
				cell: ({ row: { original } }) => {
					const { paymentStatus } = original;
					let text = '';
					switch (paymentStatus) {
						case 'waitingPayPlaceOrder':
							text = '待收预付款';
							break;
						default:
							break;
					}
					return (
						<Badge bg='danger' className='me-2'>
							<span className='fs-11'>{text}</span>
						</Badge>
					);
				},
			},
			{
				accessorKey: 'totalPrice',
				header: '总价',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>¥{original.totalPrice}</span>;
				},
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>{moment(original.createdAt).format('YYYY-MM-DD HH:mm')}</span>;
				},
			},
			{
				accessorKey: 'none',
				header: '',
				enableSorting: false,
				meta: {
					cellProps: {
						className: 'text-end py-2',
					},
				},
				cell: ({ row: { original } }) => {
					return (
						<CardDropdown drop='start'>
							<div className='py-2'>
								<Dropdown.Item href={`/order/detail/${original?._id}`}>查看</Dropdown.Item>
							</div>
						</CardDropdown>
					);
				},
			},
		],
		[]
	);

	const table = useAdvanceTable({
		data,
		columns: columns,
		sortable: true,
		pagination: true,
		perPage: 7,
		initialState: {
			sorting: [{ id: 'createdAt', desc: true }],
		},
	});

	return (
		<>
			<AdvanceTableProvider {...table}>
				<AdvanceTable
					headerClassName='bg-200 text-nowrap align-middle font-sans-serif'
					rowClassName='btn-reveal-trigger text-nowrap align-middle'
					tableProps={{
						className: 'fs-10 fw-semibold mb-0 overflow-hidden',
					}}
				/>
				{!isLoading && data.length === 0 && (
					<div className='my-5 w-100 text-center'>
						<p className=''>暂无预付款确认</p>
					</div>
				)}
				{isLoading && <Loader />}
				{isError && <Message animation='border' variant='primary' className='mt-3' />}
				<Card.Footer>
					<AdvanceTableFooter rowInfo navButtons />
				</Card.Footer>
			</AdvanceTableProvider>
			<PrePaymentModal
				setIsOpenPendingModal={setIsOpenPendingModal}
				isOpenPendingModal={isOpenPendingModal}
				modalPendingContent={modalPendingContent}
				refetchPrePaymentOrders={refetch}
				isPrePaymentOrderFetching={isFetching}
				setModalPendingContent={setModalPendingContent}
			/>
		</>
	);
};

export default PrePaymentOrderPendings;
