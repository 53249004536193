import React, { useMemo, useState } from 'react';
import { Badge, Card, Col, Dropdown, Offcanvas, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import AdvanceTablePagination from '../../components/common/advance-table/AdvanceTablePagination';

import { useBreakpoints } from '../../hooks/useBreakpoints';

import OrderFilteringForm from './OrderFilteringForm';

import { Link } from 'react-router-dom';
import person1 from '../../assets/img/team/1-thumb.png';
import person3 from '../../assets/img/team/14-thumb.png';
import person2 from '../../assets/img/team/25-thumb.png';
import person4 from '../../assets/img/team/3-thumb.png';
import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import SubtleBadge from '../../components/common/SubtleBadge';
import useAdvanceTable from '../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../providers/AdvanceTableProvider';
import ChartHeader from './ChartHeader';
import OrderChart from './OrderChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import CardDropdown from '../../components/common/CardDropdown';
import { useSelector } from 'react-redux';
import { useOrderContext } from '../../providers/OrderProvider';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const ticketsBadges = [
	{ content: '最近', type: 'success' },
	{ content: '超期', type: 'danger' },
	{ content: '剩余', type: 'warning' },
	{ content: '已响应', type: 'info' },
	{ content: '关闭', type: 'secondary' },
];

export const tickets = [
	{
		id: 1,
		name: 'Emma Watson',
		avatar: {
			name: 'Emma Watson',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Synapse Design #1125',
		status: ticketsBadges[0],
		priority: {
			title: 'Urgent',
			color: '#e63757',
			data: 100,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 2,
		name: 'Luke',
		avatar: {
			name: 'Luke',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Change of refund my last buy | Order #125631',
		status: ticketsBadges[1],
		priority: {
			title: 'High',
			color: '#F68F57',
			data: 75,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 3,
		name: 'Finley',
		avatar: {
			img: person1,
			size: 'xl',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[2],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Nowrin',
		date: '2d ago',
	},
	{
		id: 4,
		name: 'Peter Gill',
		avatar: {
			name: 'Peter Gill',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[3],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: 'Nowrin',
		date: '2d ago',
	},
	{
		id: 5,
		name: 'Freya',
		avatar: {
			img: person2,
			size: 'xl',
		},
		subject: 'Contact Froms #3264',
		status: ticketsBadges[4],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 6,
		name: 'Morrison',
		avatar: {
			name: 'Morrison',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[3],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 7,
		name: 'Morrison Banneker',
		avatar: {
			name: 'Morrison Banneker',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[4],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 8,
		name: 'Aar Kay',
		avatar: {
			img: person3,
			size: 'xl',
		},
		subject: 'Regarding Falcon Theme #3262',
		status: ticketsBadges[0],
		priority: {
			title: 'High',
			color: '#F68F57',
			data: 75,
		},
		agent: '',
		date: '2d ago',
	},
	{
		id: 9,
		name: 'Fadil Badr',
		avatar: {
			name: 'Fadil Badr',
			size: 'xl',
			round: 'circle',
		},
		subject: 'i would like to buy theme #3261',
		status: ticketsBadges[4],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 10,
		name: 'Regina Kempt',
		avatar: {
			img: person4,
			size: 'xl',
		},
		subject: 'Theme info (icons) #3260',
		status: ticketsBadges[4],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 11,
		name: 'Caleb',
		avatar: {
			name: 'Caleb',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Phishing link #3259',
		status: ticketsBadges[0],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: '',
		date: '2d ago',
	},
	{
		id: 12,
		name: 'Fadil Badr',
		avatar: {
			name: 'Fadil Badr',
			size: 'xl',
			round: 'circle',
		},
		subject: 'i would like to buy theme #3261',
		status: ticketsBadges[4],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 13,
		name: 'Regina Kempt',
		avatar: {
			img: person4,
			size: 'xl',
		},
		subject: 'Theme info (icons) #3260',
		status: ticketsBadges[4],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 14,
		name: 'Caleb',
		avatar: {
			name: 'Caleb',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Phishing link #3259',
		status: ticketsBadges[0],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: '',
		date: '2d ago',
	},
	{
		id: 15,
		name: 'Emma Watson',
		avatar: {
			name: 'Emma Watson',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Synapse Design #1125',
		status: ticketsBadges[0],
		priority: {
			title: 'Urgent',
			color: '#e63757',
			data: 100,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 16,
		name: 'Luke',
		avatar: {
			name: 'Luke',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Change of refund my last buy | Order #125631',
		status: ticketsBadges[1],
		priority: {
			title: 'High',
			color: '#F68F57',
			data: 75,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 17,
		name: 'Finley',
		avatar: {
			img: person1,
			size: 'xl',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[2],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Nowrin',
		date: '2d ago',
	},
	{
		id: 18,
		name: 'Peter Gill',
		avatar: {
			name: 'Peter Gill',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[3],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: 'Nowrin',
		date: '2d ago',
	},
	{
		id: 19,
		name: 'Freya',
		avatar: {
			img: person2,
			size: 'xl',
		},
		subject: 'Contact Froms #3264',
		status: ticketsBadges[4],
		priority: {
			title: 'Urgent',
			color: '#e63757',
			data: 100,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 20,
		name: 'Morrison',
		avatar: {
			name: 'Morrison',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[3],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 21,
		name: 'Morrison Banneker',
		avatar: {
			name: 'Morrison Banneker',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[4],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 22,
		name: 'Aar Kay',
		avatar: {
			img: person3,
			size: 'xl',
		},
		subject: 'Regarding Falcon Theme #3262',
		status: ticketsBadges[0],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: '',
		date: '2d ago',
	},
	{
		id: 23,
		name: 'Caleb',
		avatar: {
			name: 'Caleb',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Phishing link #3259',
		status: ticketsBadges[0],
		priority: {
			title: 'High',
			color: '#F68F57',
			data: 75,
		},
		agent: '',
		date: '2d ago',
	},
	{
		id: 24,
		name: 'Fadil Badr',
		avatar: {
			name: 'Fadil Badr',
			size: 'xl',
			round: 'circle',
		},
		subject: 'i would like to buy theme #3261',
		status: ticketsBadges[4],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 25,
		name: 'Regina Kempt',
		avatar: {
			img: person4,
			size: 'xl',
		},
		subject: 'Theme info (icons) #3260',
		status: ticketsBadges[4],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 26,
		name: 'Emma Watson',
		avatar: {
			name: 'Emma Watson',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Synapse Design #1125',
		status: ticketsBadges[0],
		priority: {
			title: 'Urgent',
			color: '#e63757',
			data: 100,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 27,
		name: 'Luke',
		avatar: {
			name: 'Luke',
			size: 'xl',
			round: 'circle',
		},
		subject: 'Change of refund my last buy | Order #125631',
		status: ticketsBadges[1],
		priority: {
			title: 'High',
			color: '#F68F57',
			data: 75,
		},
		agent: 'Anindya',
		date: '2d ago',
	},
	{
		id: 28,
		name: 'Aar Kay',
		avatar: {
			img: person3,
			size: 'xl',
		},
		subject: 'Regarding Falcon Theme #3262',
		status: ticketsBadges[0],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: '',
		date: '2d ago',
	},
	{
		id: 29,
		name: 'Morrison Banneker',
		avatar: {
			name: 'Morrison Banneker',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[4],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 30,
		name: 'Morrison',
		avatar: {
			name: 'Morrison',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[3],
		priority: {
			title: 'High',
			color: '#F68F57',
			data: 75,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
	{
		id: 31,
		name: 'Finley',
		avatar: {
			img: person1,
			size: 'xl',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[2],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Nowrin',
		date: '2d ago',
	},
	{
		id: 32,
		name: 'Peter Gill',
		avatar: {
			name: 'Peter Gill',
			size: 'xl',
			round: 'circle',
		},
		subject: 'I need your help #2256',
		status: ticketsBadges[3],
		priority: {
			title: 'Low',
			color: '#00D27B',
			data: 25,
		},
		agent: 'Nowrin',
		date: '2d ago',
	},
	{
		id: 33,
		name: 'Freya',
		avatar: {
			img: person2,
			size: 'xl',
		},
		subject: 'Contact Froms #3264',
		status: ticketsBadges[4],
		priority: {
			title: 'Medium',
			color: '#2A7BE4',
			data: 50,
		},
		agent: 'Khalid',
		date: '2d ago',
	},
];
const StatisticsScreen = () => {
	const [show, setShow] = useState(false);
	const { breakpoints } = useBreakpoints();
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { userInfo } = useSelector((state) => state.auth);

	const {
		orderPendingForManager,
		orderPendingForFinance,
		orderPendingForBuyer,
		orderPendingForAssistant,
		orderPendingForSale,
		loadingManagerPending,
		loadingFinancePending,
		loadingBuyerPending,
		loadingAssistantPending,
		loadingSalePending,
		loadingManagerPendingError,
		loadingAssistantPendingError,
		loadingFinancePendingError,
		loadingBuyerPendingError,
		loadingSalePendingError,
	} = useOrderContext();

	//const {orderStatistics} =
	const columns = useMemo(() => {
		const userColumns = [
			{
				accessorKey: 'orderId',
				header: '订单编号',
				meta: {
					headerProps: { className: 'sticky-column text-900' },
					cellProps: {
						className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2',
					},
				},
				cell: ({ row: { original } }) => {
					const { orderId, _id } = original;

					return (
						<>
							<div className='d-flex justify-content-center align-items-center'>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: 'fixed' }} id='basePriceTooltip'>
											<FontAwesomeIcon icon='fa-spinner' className={classNames('me-2', `text-info`)} />
											订单进行中
										</Tooltip>
									}
								>
									<FontAwesomeIcon icon='fa-spinner' className={classNames('me-2', `text-info`)} />
								</OverlayTrigger>

								<Link to={`/order/detail/${_id}`} className='text-info'>
									{orderId}
								</Link>
							</div>
						</>
					);
				},
			},
			{
				accessorKey: 'customerInfo',
				header: '客户名称',
				meta: {
					headerProps: { className: 'pe-3 text-900 text-start' },
					cellProps: { className: 'text-start' },
				},
				cell: ({ row: { original } }) => {
					const { customerInfo } = original;
					return <span className='text-info'>{customerInfo.customerName}</span>;
				},
			},
			{
				accessorKey: 'deliveryStatus',
				header: '物流状态',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { deliveryStatus } = original;
					let bg = '';
					let icon = '';
					let text = '';
					switch (deliveryStatus) {
						case 'waitingDelivery':
							bg = 'warning';
							icon = 'stream';
							text = '待发货';
							break;
						case 'waitingAccept':
							bg = 'info';
							icon = 'stream';
							text = '待验收';
							break;
						case 'waitingComplete':
							bg = 'info';
							icon = 'stream';
							text = '待完成';
							break;
						case 'deliveryComplete':
							bg = 'success';
							icon = 'check';
							text = '物流完成';
							break;
						default:
							break;
					}
					return (
						<SubtleBadge pill={deliveryStatus === 'deliveryComplete'} bg={bg} className='me-2'>
							<FontAwesomeIcon icon={icon} className='me-2' />
							<span className='fs-11'>{text}</span>
						</SubtleBadge>
					);
				},
			},
			{
				accessorKey: 'saleUser.name',
				header: '销售员',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { saleUser } = original;
					return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
				},
			},
			{
				accessorKey: 'assistantUser.name',
				header: '销售助理',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { assistantUser } = original;
					return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
				},
			},
			{
				accessorKey: 'totalPrice',
				header: '总价',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>¥{original.totalPrice}</span>;
				},
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>{moment(original.createdAt).format('YYYY-MM-DD HH:mm')}</span>;
				},
			},
			{
				accessorKey: 'none',
				header: '',
				enableSorting: false,
				meta: {
					cellProps: {
						className: 'text-end py-2',
					},
				},
				cell: ({ row: { original } }) => {
					return (
						<CardDropdown drop='start'>
							<div className='py-2'>
								<Dropdown.Item href={`/order/detail/${original?._id}`}>查看</Dropdown.Item>
							</div>
						</CardDropdown>
					);
				},
			},
		];
		if (userInfo.role === 'manager') {
			const b = [
				{
					accessorKey: 'saleUser.name',
					header: '销售员',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { saleUser } = original;
						return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
					},
				},
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
			];
			userColumns.splice(1, 1, ...b);
		}
		if (['sale', 'user'].includes(userInfo.role)) {
			const x = [
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
				{
					accessorKey: 'orderTask',
					header: '订单任务',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { orderTask, _id } = original;
						return (
							<Badge bg='danger' className='me-2'>
								<FontAwesomeIcon icon='fa-solid fa-bolt' className='me-2' />
								<span className='fs-11'>
									<Link to={`/order/detail/${_id}`} className='text-white'>
										{orderTask}
									</Link>
								</span>
							</Badge>
						);
					},
				},
			];
			userColumns.splice(1, 1, ...x);
		}
		if (userInfo.role === 'assistant') {
			const a = [
				{
					accessorKey: 'saleUser.name',
					header: '销售员',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { saleUser } = original;
						return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
					},
				},
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
				{
					accessorKey: 'orderTask',
					header: '订单任务',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { orderTask } = original;
						return (
							<Badge bg='danger' className='me-2'>
								<FontAwesomeIcon icon='fa-solid fa-bolt' className='me-2' />
								<span className='fs-11'>{orderTask}</span>
							</Badge>
						);
					},
				},
			];
			userColumns.splice(1, 1, ...a);
		}
		if (userInfo.role === 'buyer') {
			const buyerColumns = [
				{
					accessorKey: 'customerInfo',
					header: '客户名称',
					meta: {
						headerProps: { className: 'pe-3 text-900 text-start' },
						cellProps: { className: 'text-start' },
					},
					cell: ({ row: { original } }) => {
						const { customerInfo } = original;
						return <span className='text-info'>{customerInfo.customerName}</span>;
					},
				},
				{
					accessorKey: 'paymentStatus',
					header: '支付状态',
					meta: {
						headerProps: { className: 'text-900' },
					},
					cell: ({ row: { original } }) => {
						const { paymentStatus, isCompleted } = original;

						let bg = '';
						let icon = '';
						let text = '';
						switch (paymentStatus) {
							case 'waitingPayPlaceOrder':
								bg = 'warning';
								icon = 'stream';
								text = '待收预付款';
								break;
							case 'waitingPayDelivery':
								bg = 'warning';
								icon = 'stream';
								text = '待收发货尾款';
								break;
							case 'waitingPayAccept':
								bg = 'warning';
								icon = 'stream';
								text = '待收验收尾款';
								break;
							case 'waitingPayComplete':
								bg = 'warning';
								icon = 'stream';
								text = '待支付完成';
								break;
							case 'payComplete':
								bg = 'success';
								icon = 'check';
								text = '支付完成';
								break;
							default:
								break;
						}

						if (!isCompleted && paymentStatus === 'payComplete') {
							return (
								<Badge bg='success' className='me-2'>
									<FontAwesomeIcon icon='success' className='me-2' />
									<span className='fs-11'>当前无应收</span>
								</Badge>
							);
						} else {
							return (
								<SubtleBadge pill={paymentStatus === 'payComplete'} bg={bg} className='me-2'>
									<FontAwesomeIcon icon={icon} className='me-2' />
									<span className='fs-11'>{text}</span>
								</SubtleBadge>
							);
						}
					},
				},
			];
			userColumns.splice(1, 1, ...buyerColumns);
			return userColumns.filter((column) => column.accessorKey !== 'currentAccountsReceivable');
		}
		return userColumns;
	}, [userInfo.role]);

	const table = useAdvanceTable({
		data:
			userInfo.role === 'buyer'
				? orderPendingForBuyer
				: ['sale', 'user'].includes(userInfo.role)
				? orderPendingForSale
				: userInfo.role === 'assistant'
				? orderPendingForAssistant
				: userInfo.role === 'manager'
				? orderPendingForManager
				: orderPendingForFinance,
		columns: columns,
		sortable: true,
		pagination: true,
		initialState: {
			sorting: [{ id: 'createdAt', desc: true }],
		},
		selection: true,
		perPage: 11,
		selectionColumnWidth: 52,
	});

	return (
		<Row className='gx-3'>
			<Col xxl={3} xl={4}>
				{breakpoints.down('xl') ? (
					<Offcanvas show={show} onHide={handleClose} placement='start' className='dark__bg-card-dark'>
						<Offcanvas.Header closeButton className='bg-body-tertiary'>
							<h6 className='fs-9 mb-0 fw-semibold'>过滤器</h6>
						</Offcanvas.Header>
						<OrderFilteringForm />
					</Offcanvas>
				) : (
					<OrderFilteringForm />
				)}
			</Col>
			<Col xxl={9} xl={8}>
				<AdvanceTableProvider {...table}>
					<Card>
						<Card.Header className='border-bottom border-200 px-0'>
							<ChartHeader layout='table-view' handleShow={handleShow} />
						</Card.Header>

						<Card.Body className='p-0'>
							<OrderChart />
							<AdvanceTable
								headerClassName='bg-body-tertiary align-middle'
								rowClassName='btn-reveal-trigger align-middle'
								tableProps={{
									size: 'sm',
									className: 'fs-10 mb-0 overflow-hidden',
								}}
							/>
							{loadingManagerPending && <Loader />}
							{loadingFinancePending && <Loader />}
							{loadingBuyerPending && <Loader />}
							{loadingSalePending && <Loader />}
							{loadingAssistantPending && <Loader />}
							{loadingManagerPendingError && <Message animation='border' variant='primary' className='mt-3' />}
							{loadingFinancePendingError && <Message animation='border' variant='primary' className='mt-3' />}
							{loadingBuyerPendingError && <Message animation='border' variant='primary' className='mt-3' />}
							{loadingSalePendingError && <Message animation='border' variant='primary' className='mt-3' />}
							{loadingAssistantPendingError && <Message animation='border' variant='primary' className='mt-3' />}
						</Card.Body>
						<Card.Footer>
							<AdvanceTablePagination />
						</Card.Footer>
					</Card>
				</AdvanceTableProvider>
			</Col>
		</Row>
	);
};

export default StatisticsScreen;
