import { createContext, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setConfig } from '../slices/configSlice';

import { settings } from '../config.js';
import { getColor, getItemFromStore } from '../utils/utils';
import useToggleStylesheet from '../hooks/useToggleStyle';

export const AppContext = createContext(settings);

const AppProvider = ({ children }) => {
	const dispatch = useDispatch();
	const [isDark] = useState(getItemFromStore('isDark', settings.isDark));
	const [theme] = useState(getItemFromStore('theme', settings.theme));
	const [isFluid] = useState(getItemFromStore('isFluid', settings.isFluid));
	const [isNavbarVerticalCollapsed] = useState(getItemFromStore('isNavbarVerticalCollapsed', settings.isNavbarVerticalCollapsed));

	const { isLoaded } = useToggleStylesheet(false, isDark);

	useEffect(() => {
		const isDarkInit = theme === 'auto' ? window.matchMedia('(prefers-color-scheme: dark)').matches : theme === 'dark';
		document.documentElement.setAttribute('data-bs-theme', isDarkInit ? 'dark' : 'light');
		dispatch(setConfig({ key: 'isDark', value: isDarkInit }));
		dispatch(setConfig({ key: 'theme', value: theme }));
	}, [dispatch, theme]);

	const changeTheme = (theme) => {
		const isDarkChange = theme === 'auto' ? window.matchMedia('(prefers-color-scheme: dark)').matches : theme === 'dark';

		document.documentElement.setAttribute('data-bs-theme', isDarkChange ? 'dark' : 'light');

		dispatch(setConfig({ key: 'isDark', value: isDarkChange }));
		dispatch(setConfig({ key: 'theme', value: theme }));
	};

	const getThemeColor = (name) => getColor(name);

	if (!isLoaded) {
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					backgroundColor: isDark ? getThemeColor('dark') : getThemeColor('light'),
				}}
			/>
		);
	}

	return <AppContext.Provider value={{ isFluid, isNavbarVerticalCollapsed, changeTheme, getThemeColor }}>{children}</AppContext.Provider>;
};

AppProvider.prototype = { children: PropTypes.node };

export const useAppContext = () => useContext(AppContext);

export default AppProvider;
