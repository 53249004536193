import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from '../../../../components/common/Flex';
import SubtleBadge from '../../../../components/common/SubtleBadge';
import Message from '../../../../components/Message';
import { toast } from 'react-toastify';
import { useAddAccountsReceivableMutation, useDeliverOrderMutation } from '../../../../slices/ordersApiSlice';
import { useOrderContext } from '../../../../providers/OrderProvider';
import timeZone from 'moment-timezone';
import moment from 'moment/moment.js';

const getCircleStackIcon = (icon, transform) => (
	<span className='fa-stack ms-n1 me-3'>
		<FontAwesomeIcon icon='circle' className='text-200 fa-stack-2x' />
		<FontAwesomeIcon icon={icon} transform={transform ?? ''} className='text-primary fa-stack-1x' inverse />
	</span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
	<Flex className='mt-3'>
		{getCircleStackIcon(icon)}
		<div className='flex-1'>
			<h6>{heading}</h6>
			{children || <p className='mb-0 text-justify'>{content}</p>}
		</div>
	</Flex>
);

const ContractModal = ({ setIsOpenPendingModal, isOpenPendingModal, modalPendingContent, setModalPendingContent, refetchContractOrders, isFetchingContractOrders }) => {
	const { refetchBuyerPending } = useOrderContext();
	const { isDark } = useSelector((state) => state.config);
	const [deliverOrder, { isLoading: loadingDeliver }] = useDeliverOrderMutation();
	const [addOrderAccountsReceivable, { isLoading: savingAR }] = useAddAccountsReceivableMutation();

	const handleClose = () => {
		setIsOpenPendingModal(!isOpenPendingModal);
		setModalPendingContent({});
		refetchContractOrders();
		refetchBuyerPending();
	};
	const { _id, orderId, customerInfo, orderNotes, scheduleNodes, totalPrice, approvalStatus, percentPayment, prePaymentPercent, isPlaceOrderPaid, isDeliveryPaid } =
		modalPendingContent;

	const deliverHandler = async () => {
		try {
			// 检查订单是否有发货后收款计划节点
			if (scheduleNodes.length === 0) {
				await deliverOrder(_id).unwrap();
				toast.success('订单已发货');
				await addOrderAccountsReceivable({
					orderId: _id,
					details: { accountsReceivable: 0, title: '发货', paymentDays: 0, totalPrice: totalPrice },
				}).unwrap();
			} else {
				const deliverScheduleNode = scheduleNodes.filter((node) => node.title === '发货');
				if (deliverScheduleNode.length !== 0 && deliverScheduleNode[0].paymentAmount > 0) {
					await deliverOrder(_id).unwrap();
					toast.success('订单已发货');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: { accountsReceivable: deliverScheduleNode[0].paymentAmount, title: '发货', paymentDays: deliverScheduleNode[0].paymentDays, totalPrice: totalPrice },
					}).unwrap();
				} else {
					await deliverOrder(_id).unwrap();
					toast.success('订单已发货');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: { accountsReceivable: 0, title: '发货', paymentDays: 0, totalPrice: totalPrice },
					}).unwrap();
				}
			}
			handleClose();
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	return (
		<Modal show={isOpenPendingModal} onHide={handleClose} contentClassName='border' centered size='lg'>
			<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className='bg-body-tertiary px-x1 border-bottom-0'>
				<Modal.Title>
					<Flex>
						<h5 className='mb-0'>{orderId}</h5>
						{approvalStatus === 'inProcessOfApproval' && (
							<Badge bg='danger' className='ms-2 fs-10'>
								待审批
							</Badge>
						)}
					</Flex>

					{customerInfo?.customerName && (
						<p className='mb-0 fs-10 mt-1 fw-normal'>
							客户名称：<a href='#!'>{customerInfo.customerName}</a>
						</p>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='px-x1 pb-x1 pt-1 fs-10'>
				{customerInfo?.staffName && (
					<EventModalMediaContent icon='align-left' heading='客户信息'>
						<Flex justifyContent=''>
							<p className='mb-0 fs-10 mt-1 fw-normal me-3'>
								联系人：<a href='#!'>{customerInfo.staffName}</a>
							</p>
							{customerInfo?.phone && (
								<p className='mb-0 fs-10 mt-1 fw-normal'>
									电话：<a href='#!'>{customerInfo.phone}</a>
								</p>
							)}
						</Flex>
					</EventModalMediaContent>
				)}

				{totalPrice && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单总价' content={`¥${totalPrice}`} />}
				{percentPayment > 0 && (
					<>
						<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付款'>
							<span className='me-2'>¥{percentPayment}</span>
							<SubtleBadge>{prePaymentPercent}%</SubtleBadge>
						</EventModalMediaContent>
					</>
				)}

				{scheduleNodes && scheduleNodes.length !== 0 && (
					<EventModalMediaContent icon='clock' heading='尾款计划'>
						<ul className='list-unstyled timeline mb-0'>
							{scheduleNodes.map((schedule, index) => (
								<li key={index} className='mb-1'>
									<span className='me-3'>
										{schedule.title}后 {schedule.paymentDays} 天
									</span>
									<span className='me-3'>应收账款：¥{schedule.paymentAmount}</span>
									<SubtleBadge>{schedule.paymentPercent}%</SubtleBadge>
								</li>
							))}
						</ul>
					</EventModalMediaContent>
				)}

				<EventModalMediaContent icon='clock' heading='应收账款'>
					<ul className='list-unstyled timeline mb-0'>
						{modalPendingContent.accountsReceivables &&
							modalPendingContent.accountsReceivables.length !== 0 &&
							modalPendingContent.accountsReceivables.map((ar) => (
								<li className='mb-1'>
									{ar.isPaid ? (
										<SubtleBadge className='me-2' bg='success'>
											已收款
										</SubtleBadge>
									) : (
										<SubtleBadge className='me-2' bg='warning'>
											待确认
										</SubtleBadge>
									)}
									<span className={classNames('me-3', !ar.isPaid && 'fw-semibold')}>{ar.title}尾款</span>
									<span className={classNames('me-3', !ar.isPaid && 'fw-semibold')}>¥{ar.accountsReceivable}</span>
									{ar.isPaid ? (
										<>
											<FontAwesomeIcon icon='check' className='text-success' />
											<span className='text-success-emphasis fw-semibold fs-11 ms-2'>
												{ar.title}款已支付在 {timeZone.tz(moment(ar.deliveryPaidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
											</span>
										</>
									) : (
										<>
											<FontAwesomeIcon icon='clock' className='text-warning' />
										</>
									)}
								</li>
							))}
					</ul>
				</EventModalMediaContent>

				{orderNotes && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单备注' content={orderNotes} />}
			</Modal.Body>
			{isFetchingContractOrders && <Message>正在更新应收账款...</Message>}

			<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
				<>
					<Button onClick={deliverHandler} disabled={!isPlaceOrderPaid && isDeliveryPaid} size='sm'>
						<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
						标记为已发货 {loadingDeliver && <Spinner size='sm' />}
						{savingAR && <Spinner size='sm' />}
					</Button>
					<Button as={Link} to={`/order/detail/${_id}`} variant='falcon-primary' size='sm'>
						<span>查看更多细节</span>
						<FontAwesomeIcon icon='angle-right' className='fs-11 ms-1' />
					</Button>
				</>
			</Modal.Footer>
		</Modal>
	);
};

export default ContractModal;
