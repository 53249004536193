import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, ListGroup, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import { useAcceptOrderMutation, useAddAccountsReceivableMutation, useDeliverOrderMutation, usePayOrdersMutation } from '../../slices/ordersApiSlice';
import formatDate from '../../utils/formatDate';
import { useState } from 'react';

const OrderDetailOperationCard = ({ order, refetch, placeOrderNode, deliverNode, acceptNode, isOrderFetching }) => {
	const { userInfo } = useSelector((state) => state.auth);
	const [currentButtonValue, setCurrentButtonValue] = useState('');
	const [deliverOrder, { isLoading: loadingDeliver, isSuccess: deliverSuccess }] = useDeliverOrderMutation();
	const [acceptOrder, { isLoading: loadingAccept, isSuccess: acceptSuccess }] = useAcceptOrderMutation();
	const [addOrderAccountsReceivable, { isLoading: savingAR }] = useAddAccountsReceivableMutation();
	const [payOrder, { isLoading: loadingPay, isSuccess: paySuccess }] = usePayOrdersMutation();

	const onPayOrder = async (accountsReceivable, title, orderOId, orderId) => {
		await payOrder({ orderOId, details: { payAccountsReceivable: accountsReceivable, title } });
		toast.success(orderId + '订单' + title + '款项已核销!');
		refetch();
	};
	const deliverHandler = async () => {
		try {
			// 检查订单是否有发货后收款计划节点
			if (order.scheduleNodes.length === 0) {
				await deliverOrder(order._id).unwrap();
				toast.success('订单已发货');
				await addOrderAccountsReceivable({
					orderId: order._id,
					details: { accountsReceivable: 0, title: '发货', paymentDays: 0, totalPrice: order.totalPrice },
				}).unwrap();
				refetch();
			} else {
				const deliverScheduleNode = order.scheduleNodes.filter((node) => node.title === '发货');
				if (deliverScheduleNode.length !== 0 && deliverScheduleNode[0].paymentAmount > 0) {
					await deliverOrder(order._id).unwrap();
					toast.success('订单已发货');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: deliverScheduleNode[0].paymentAmount, title: '发货', paymentDays: deliverScheduleNode[0].paymentDays, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				} else {
					await deliverOrder(order._id).unwrap();
					toast.success('订单已发货');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: 0, title: '发货', paymentDays: 0, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				}
			}
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	const acceptHandler = async () => {
		try {
			// 检查订单是否有验收后收款计划节点
			if (order.scheduleNodes.length === 0) {
				await acceptOrder(order._id).unwrap();
				toast.success('订单已验收完毕');
				await addOrderAccountsReceivable({
					orderId: order._id,
					details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: order.totalPrice },
				}).unwrap();
				refetch();
			} else {
				const acceptScheduleNode = order.scheduleNodes.filter((node) => node.title === '验收');
				if (acceptScheduleNode.length !== 0 && acceptScheduleNode[0].paymentAmount >= 0) {
					await acceptOrder(order._id).unwrap();
					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: acceptScheduleNode[0].paymentAmount, title: '验收', paymentDays: acceptScheduleNode[0].paymentDays, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				} else {
					await acceptOrder(order._id).unwrap();
					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				}
			}
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	return (
		<Card className='sticky-sidebar z-1'>
			<Card.Header>
				<Card.Title className='mb-0'>订单标记</Card.Title>
			</Card.Header>
			<ListGroup variant='flush'>
				{userInfo.role === 'buyer' && !order.isDelivered && (
					<ListGroup.Item>
						<p>
							<strong>操作订单</strong>
						</p>
						<Button onClick={deliverHandler} className='m-3' disabled={placeOrderNode && !placeOrderNode[0]?.isPaid} hidden={deliverSuccess}>
							<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
							标记为已发货 {loadingDeliver && <Spinner size='sm' />}
							{savingAR && <Spinner size='sm' />}
						</Button>
					</ListGroup.Item>
				)}

				{['sale', 'assistant', 'manager'].includes(userInfo.role) && !order.isAccepted && ['approved', 'systemApproved'].includes(order.approvalStatus) && (
					<ListGroup.Item>
						<p>
							<strong>操作订单</strong>
						</p>
						<Button onClick={acceptHandler} className='m-3' disabled={(placeOrderNode && !placeOrderNode[0]?.isPaid) || !order.isDelivered} hidden={acceptSuccess}>
							{(placeOrderNode && !placeOrderNode[0]?.isPaid) || !order.isDelivered ? (
								<>
									<FontAwesomeIcon icon='fa-truck-ramp-box' className='me-2' />
									发货后可验收
								</>
							) : (
								<>
									<FontAwesomeIcon icon='fa-regular fa-square-check' className='me-2' />
									验收完成
								</>
							)}
							{loadingAccept && <Spinner size='sm' />}
							{savingAR && <Spinner size='sm' />}
						</Button>
					</ListGroup.Item>
				)}

				{['finance'].includes(userInfo.role) &&
					['approved', 'systemApproved'].includes(order.approvalStatus) &&
					order.accountsReceivables.length !== 0 &&
					order.accountsReceivables.map((ar) => {
						if (!ar.isPaid) {
							return (
								<ListGroup.Item>
									<Button
										className='my-3 me-9 ms-1'
										onClick={(e) => {
											setCurrentButtonValue(e.currentTarget.value);
											onPayOrder(ar.accountsReceivable, ar.title, order._id, order.orderId);
										}}
										value={ar.title}
										hidden={paySuccess}
									>
										<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
										客户已支付{ar.title}款
									</Button>
									{loadingPay && ar.title === currentButtonValue && <Spinner size='sm' className='ms-2 text-white' />}
								</ListGroup.Item>
							);
						} else {
							return <></>;
						}
					})}

				{['approved', 'systemApproved'].includes(order.approvalStatus) && (
					<ListGroup.Item>
						{isOrderFetching && <Message>正在更新订单...</Message>}
						<strong>物流标记</strong>
						<ul className='timeline-order m-3'>
							<li className='timeline-item'>
								<strong>
									{order.isDelivered ? (
										<Message variant='success'>
											<FontAwesomeIcon icon='fa-truck-fast' className='me-2' />
											订单已发货在 {formatDate(order.deliveredAt)}
										</Message>
									) : (
										<Message variant='warning'>
											<FontAwesomeIcon icon='fa-solid fa-cubes' className='me-2' />
											未发货
										</Message>
									)}
								</strong>
							</li>
							<li className='timeline-item'>
								<strong>
									{order.isAccepted ? (
										<Message variant='success'>
											<FontAwesomeIcon icon='fa-box-open' className='me-2' />
											订单已验收在 {formatDate(order.acceptedAt)}
										</Message>
									) : (
										<Message variant='warning'>
											<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
											等待验收
										</Message>
									)}
								</strong>
							</li>
							<li className='timeline-item'>
								<strong>
									{order.isCompleted ? (
										<Message variant='success'>
											<FontAwesomeIcon icon='fa-thumbs-up' className='me-2' />
											订单已完成在 {formatDate(order.completedAt)}
										</Message>
									) : (
										<Message variant='warning'>
											<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
											待完成订单
										</Message>
									)}
								</strong>
							</li>
						</ul>
					</ListGroup.Item>
				)}
			</ListGroup>
		</Card>
	);
};

export default OrderDetailOperationCard;
