import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import timeZone from 'moment-timezone';
import moment from 'moment/moment.js';
import { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SubtleBadge from '../../components/common/SubtleBadge';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { useGetOrderDetailsQuery } from '../../slices/ordersApiSlice';
import OrderDetailOperationCard from './OrderDetailOperationCard';

const OrderDetailScreen = () => {
	const { id: orderId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const { data: order, refetch, isLoading, error, isFetching: isOrderFetching } = useGetOrderDetailsQuery(orderId);
	const navigate = useNavigate();

	const [placeOrderNode, setPlaceOrderNode] = useState([]);
	const [deliverNode, setDeliverNode] = useState([]);
	const [acceptNode, setAcceptNode] = useState([]);

	useEffect(() => {
		setPlaceOrderNode(order?.accountsReceivables.filter((node) => node.title === '预付'));
		setDeliverNode(order?.accountsReceivables.filter((node) => node.title === '发货'));
		setAcceptNode(order?.accountsReceivables.filter((node) => node.title === '验收'));
	}, [order?.accountsReceivables]);

	return isLoading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'></Message>
	) : (
		<>
			<Row className='g-3'>
				<Col xs={12}>
					<Card className='mb-1'>
						<Card.Body>
							<Row className='flex-between-center'>
								<Col md={6}>
									<h5 className='my-2 mb-md-0 me-1'>
										订单编号 # {order.orderId}
										{order.approvalStatus === 'inProcessOfApproval' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle ' bg='info'>
												<FontAwesomeIcon icon='fa-rotate' spin spinReverse className='me-1' />
												<span>待审批</span>
											</Badge>
										)}
										{order.approvalStatus === 'approved' && !order.isPlaceOrderPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='success'>
												<FontAwesomeIcon icon='check' className='me-1' />
												<span>审批通过</span>
											</Badge>
										)}
										{order.approvalStatus === 'reject' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='danger'>
												<FontAwesomeIcon icon='xmark' className='me-1' />
												<span>被驳回</span>
											</Badge>
										)}
										{placeOrderNode && placeOrderNode.length !== 0 && !placeOrderNode[0]?.isPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-user-clock' className='me-1' />
												待确认预付款
											</Badge>
										)}
										{deliverNode && deliverNode.length !== 0 && !deliverNode[0]?.isPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-user-clock' className='me-1' />
												待确认发货款
											</Badge>
										)}
										{acceptNode && acceptNode.length !== 0 && !acceptNode[0]?.isPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-user-clock' className='me-1' />
												待确认验收款
											</Badge>
										)}
										{['inProcessOfApproval'].includes(order.approvalStatus) && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='danger'>
												<FontAwesomeIcon icon='feather' className='me-1' />
												待审批
											</Badge>
										)}
										{!order.isDelivered && order.isPlaceOrderPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-people-carry-box' className='me-2' />
												待发货/安装/提供服务
											</Badge>
										)}
										{!order.isAccepted && order.isDeliveryPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-list-check' className='me-1' />
												待验收
											</Badge>
										)}
										{!order.isCompleted ? (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
												待完成订单
											</Badge>
										) : (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='success'>
												<FontAwesomeIcon icon='check-circle' className='me-1' />
												订单已全部完成
											</Badge>
										)}
									</h5>
								</Col>

								<Col xs='auto'>
									{order.approvalStatus === 'reject' && (
										<>
											<Button variant='outline-success' className='me-2'>
												<FontAwesomeIcon icon='rotate' className='me-2' />
												修改并再次申请
											</Button>
										</>
									)}

									<Button variant='outline-primary' className='me-2' onClick={() => navigate(`/${userInfo.role}/orderlist`)}>
										<FontAwesomeIcon icon='list' className='me-2' />
										<span>订单列表</span>
									</Button>
									<Button variant='outline-primary' className='me-2' onClick={() => navigate(-1)}>
										<FontAwesomeIcon icon='fa-regular fa-window-restore' className='me-2' />
										<span>返回上一页</span>
									</Button>
									<Button variant='outline-primary' className='me-2' onClick={() => navigate(`/dashboard`)}>
										<FontAwesomeIcon icon='home' className='me-2' />
										<span>首页</span>
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={8}>
					<Card>
						<Card.Header>
							<Card.Title className='mb-0'>订单详情</Card.Title>
						</Card.Header>
						<Row>
							<Col>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<Row>
											<Col>
												<p>
													<strong>客户名称: </strong>
													{order.customerInfo.customerName}
												</p>
												{order.customerInfo.staffName && (
													<p>
														<strong>联系人: </strong>
														{order.customerInfo.staffName}
													</p>
												)}
												{order.customerInfo.staffPhone && (
													<p>
														<strong>电话: </strong>
														{order.customerInfo.staffPhone}
													</p>
												)}
												{order.customerInfo.staffWechat && (
													<p>
														<strong>微信: </strong>
														{order.customerInfo.staffWechat}
													</p>
												)}
												{order.customerInfo.staffEmail && (
													<p>
														<strong>邮箱: </strong>
														<a href={`mailto:${order.customerInfo.staffEmail}`}>{order.customerInfo.staffEmail}</a>
													</p>
												)}
												<p>
													<strong>地址: </strong>
													{order.customerInfo.customerAddress}
												</p>
											</Col>
											<Col>
												<ListGroup variant='flush'>
													<ListGroup.Item key={order.orderId}>
														<p>
															<strong>业务联系人: </strong>
															{order.saleUser.name}
														</p>
														{order.saleUser.phone && (
															<p>
																<strong>电话: </strong>
																{order.saleUser.phone}
															</p>
														)}
														{order.saleUser.email && (
															<p>
																<strong>邮箱: </strong>
																<a href={`mailto:${order.saleUser.email}`}>{order.saleUser.email}</a>
															</p>
														)}
													</ListGroup.Item>
													<ListGroup.Item key={order.orderId}>
														<p>
															<strong>销售助理: </strong>
															{order.assistantUser.name}
														</p>
														{order.assistantUser.phone && (
															<p>
																<strong>电话: </strong>
																{order.assistantUser.phone}
															</p>
														)}
														{order.assistantUser.email && (
															<p>
																<strong>邮箱: </strong>
																<a href={`mailto:${order.assistantUser.email}`}>{order.assistantUser.email}</a>
															</p>
														)}
													</ListGroup.Item>
												</ListGroup>
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<h5 className='my-3'>
											<strong>
												订单备注 <FontAwesomeIcon icon='fa-regular fa-comment-dots' className='text-primary' />
											</strong>
										</h5>
										<Row>
											<Col>
												{order.orderNotes !== '' ? (
													<p>
														<FontAwesomeIcon icon='fa-solid fa-quote-left' className='text-primary' />
														&nbsp;&nbsp;{order.orderNotes}&nbsp;&nbsp;
														<FontAwesomeIcon icon='fa-solid fa-quote-right' className='text-primary' />
													</p>
												) : (
													<p>无订单备注</p>
												)}
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<h5 className='my-3'>
											<strong>货款支付</strong>
										</h5>
										<p>
											<strong>支付方式: </strong>
											<span className='fw-semibold '>{order.paymentMethod}</span>
										</p>
										<Row className='mb-2'>
											<Col>产品总价</Col>
											<Col>¥ {order.itemsPrice}</Col>
										</Row>
										<Row className='mb-2'>
											<Col>剩余尾款:</Col>
											<Col>
												<strong>¥ {order.percentBalance}</strong>
											</Col>
										</Row>
										<Row className='mb-3'>
											<Col>
												<strong>总价</strong>
											</Col>
											<Col>
												<strong>¥ {order.totalPrice}</strong>
											</Col>
										</Row>
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '百分比' && (
											<>
												<Row className='mb-3'>
													<span className='mb-2 fw-semibold text'>
														<FontAwesomeIcon icon='fa-clock' className='me-2 text-primary' />
														订单付款计划
													</span>
													<ul className='list-unstyled timeline mb-0'>
														<li className='mb-1'>
															<span className='me-1'>预付金额为：</span>
															<span className='me-3 fw-semibold'>¥ {order.percentPayment}</span>
															<SubtleBadge className='me-3'>{order.prePaymentPercent} %</SubtleBadge>
														</li>
														{order.scheduleNodes.map((schedule, index) => (
															<li key={index} className='mb-1'>
																<span className='me-3'>
																	{schedule.title}尾款 <strong>{schedule.paymentDays}</strong> 天
																</span>
																<span className='me-3 fw-semibold'>¥ {schedule.paymentAmount}</span>
																<SubtleBadge>{schedule.paymentPercent}%</SubtleBadge>
															</li>
														))}
													</ul>
												</Row>
												<Row className='mb-3'>
													<span className='mb-2 fw-semibold'>
														<FontAwesomeIcon icon='fa-coins' className='me-2 text-primary' />
														应收账款
													</span>

													<ul className='list-unstyled timeline mb-0'>
														{order?.accountsReceivables.map((ar, index) => {
															const calculatedDays = timeZone.tz(moment(ar.expire_time), 'Asia/Shanghai').diff(timeZone.tz(moment(), 'Asia/Shanghai'), 'days');
															return (
																<li key={index} className='mb-1'>
																	{ar.isPaid ? (
																		<SubtleBadge className='me-3' bg='success'>
																			已收款
																		</SubtleBadge>
																	) : (
																		<SubtleBadge className='me-3' bg='warning'>
																			待确认
																		</SubtleBadge>
																	)}
																	<span className={classNames('me-3', !ar.isPaid && 'fw-semibold')}>{ar.title}款</span>
																	<span className={classNames('me-3', !ar.isPaid && 'fw-semibold')}>¥{ar.accountsReceivable}</span>
																	{ar.isPaid ? (
																		<>
																			<FontAwesomeIcon icon='check' className='text-success me-2' />
																			<span className='text-success-emphasis fw-semibold fs-11 '>
																				{ar.title}款已支付在 {timeZone.tz(moment(ar.paidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
																			</span>
																		</>
																	) : (
																		<FontAwesomeIcon icon='fa-calendar-day' className={classNames(calculatedDays < 7 ? 'text-danger' : 'text-warning')} />
																	)}

																	{!ar.isPaid && (
																		<>
																			<span className={classNames('ms-2 fw-semibold', calculatedDays < 7 ? 'text-danger' : 'text-warning')}>
																				付款到期日期 {timeZone.tz(moment(ar.expire_time), 'Asia/Shanghai').format('YYYY-MM-DD')}
																			</span>
																			<span className={classNames('ms-2 fw-semibold', calculatedDays < 7 ? 'text-danger' : 'text-warning')}>
																				{calculatedDays < 0 ? (
																					<>
																						超期 {calculatedDays} 天 <FontAwesomeIcon icon='fa-calendar' beat className='text-danger ms-2' />
																					</>
																				) : calculatedDays === 0 ? (
																					<>
																						今日到期，请及时催收款项!
																						<FontAwesomeIcon icon='fa-triangle-exclamation' beat className='text-danger ms-2' />
																					</>
																				) : (
																					`剩余 ${timeZone.tz(moment(ar.expire_time), 'Asia/Shanghai').diff(timeZone.tz(moment(), 'Asia/Shanghai'), 'days')} 天`
																				)}
																			</span>
																		</>
																	)}
																</li>
															);
														})}
													</ul>
													{isOrderFetching && <Message>正在更新订单...</Message>}
												</Row>
											</>
										)}
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '预付款' && (
											<>
												<Row className='mb-2'>
													<Col>预支付:</Col>
													<Col>
														<strong>¥ {order.prePayment}</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.prePaymentBalance}</strong>
													</Col>
												</Row>
											</>
										)}
									</ListGroup.Item>
									<ListGroup.Item className='mb-5'>
										<h5 className='my-3'>
											<strong>订单列表</strong>
										</h5>
										<ListGroup.Item className='bg-primary'>
											<Row className='text-white'>
												<Col>
													<strong>货号</strong>
												</Col>
												<Col>
													<strong>设备名称</strong>
												</Col>
												<Col>
													<strong>数量</strong>
												</Col>
												<Col>
													<strong>单价</strong>
												</Col>
												<Col>
													<strong>小计</strong>
												</Col>
											</Row>
										</ListGroup.Item>
										{order.orderItems.map((item, index) => (
											<>
												<ListGroup.Item key={index} variant={item.isDiscount && 'danger'} data-bs-theme='light' as={Link} to='#!' action>
													<Row>
														<Col>
															{item.productCode}
															{item.isDiscount && <FontAwesomeIcon icon='star' className='text-danger fs-10 ms-1' />}
														</Col>
														<Col>{item.name}</Col>
														<Col>{item.qty}</Col>
														<Col>¥{item.sellingPrice}</Col>
														<Col>¥{(item.qty * item.sellingPrice).toFixed(2)}</Col>
													</Row>
												</ListGroup.Item>
											</>
										))}
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
					</Card>
				</Col>

				<Col md={4}>
					<OrderDetailOperationCard
						order={order}
						refetch={refetch}
						placeOrderNode={placeOrderNode}
						deliverNode={deliverNode}
						acceptNode={acceptNode}
						isOrderFetching={isOrderFetching}
					/>
				</Col>
			</Row>
		</>
	);
};

export default OrderDetailScreen;
