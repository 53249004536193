import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Link, useNavigate } from 'react-router-dom';

import AdvanceTableSearchBox from '../../components/common/advance-table/AdvanceTableSearchBox';
import IconButton from '../../components/common/IconButton';

import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useAdvanceTableContext } from '../../providers/AdvanceTableProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChartHeader = ({ layout, handleShow }) => {
	const { getSelectedRowModel } = useAdvanceTableContext();
	const navigate = useNavigate();
	return (
		<div className='d-lg-flex justify-content-between'>
			<Row className='flex-between-center gy-2 px-x1'>
				<Col xs='auto' className='pe-0'>
					<h6 className='mb-0'>订单统计图表</h6>
				</Col>
				<Col xs='auto'>
					<AdvanceTableSearchBox className='input-search-width' placeholder={`根据 '客户名称' 搜索`} />
				</Col>
			</Row>
			<div className='border-bottom border-200 my-3'></div>
			<div className='d-flex align-items-center justify-content-between justify-content-lg-end px-x1'>
				<IconButton variant='falcon-default' size='sm' icon='filter' transform='shrink-4' iconAlign='middle' onClick={handleShow} className='d-xl-none'>
					<span className='d-none d-sm-inline-block ms-1'>过滤</span>
				</IconButton>
				<div className='bg-300 mx-3 d-none d-lg-block d-xl-none' style={{ width: '1px', height: '29px' }}></div>
				{getSelectedRowModel().rows.length > 0 ? (
					<div className='d-flex' style={{ width: '200px', height: '29px' }}>
						<Form.Select size='sm' aria-label='Bulk actions'>
							<option>批处理</option>
							<option value='refund'>关单</option>
							<option value='delete'>删除</option>
							<option value='archive'>存档</option>
						</Form.Select>
						<Button type='button' variant='falcon-default' size='sm' className='ms-2' style={{ width: '100px', height: '29px' }}>
							应用
						</Button>
					</div>
				) : (
					<div id='orders-actions'>
						<Dropdown align='end' className='btn-reveal-trigger d-inline-block me-2'>
							<Dropdown.Toggle split variant='falcon-default' size='sm'>
								<span className='d-none d-sm-inline-block d-xl-none d-xxl-inline-block me-1'>{layout === 'table-view' ? '表格视图' : '卡片视图'}</span>
								<FontAwesomeIcon icon='chevron-down' transform='shrink-2' />
							</Dropdown.Toggle>
							<Dropdown.Menu className='border py-0'>
								<div className='py-2'>
									<Link
										className={classNames('dropdown-item', {
											active: layout === 'table-view',
										})}
										to={`#!`}
									>
										表格视图
									</Link>
									<Link
										className={classNames('dropdown-item', {
											active: layout === 'card-view',
										})}
										to={`#!`}
									>
										卡片视图
									</Link>
								</div>
							</Dropdown.Menu>
						</Dropdown>
						<IconButton variant='falcon-default' size='sm' icon='plus' transform='shrink-3' iconAlign='middle' onClick={(e) => navigate('/order/create/new')}>
							<span className='d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1'>新建订单</span>
						</IconButton>
						<IconButton variant='falcon-default' size='sm' icon='external-link-alt' transform='shrink-3' className='mx-2' iconAlign='middle'>
							<span className='d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1'>导出</span>
						</IconButton>
						<Dropdown align='end' className='btn-reveal-trigger d-inline-block'>
							<Dropdown.Toggle split variant='falcon-default' size='sm'>
								<FontAwesomeIcon icon='ellipsis-h' className='fs-11' />
							</Dropdown.Toggle>

							<Dropdown.Menu className='border py-0'>
								<div className='py-2'>
									<Dropdown.Item>查看</Dropdown.Item>
									<Dropdown.Item>导出</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item className='text-danger'>移除</Dropdown.Item>
								</div>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				)}
			</div>
		</div>
	);
};

ChartHeader.propTypes = {
	handleShow: PropTypes.func,
	layout: PropTypes.string,
};

export default ChartHeader;
