import React from 'react';

import { Bar } from 'react-chartjs-2';
import { useAppContext } from '../../providers/AppProvider';
import { rgbaColor } from '../../utils/utils';
import { chartJsDefaultTooltip } from '../../utils/chartjs-utils';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const OrderChart = () => {
	const { getThemeColor } = useAppContext();

	const data = {
		labels: ['预付款', '发货款', '验收款', '尾款'],
		datasets: [
			{
				label: '订单总量',
				data: [12, 19, 3, 9],
				backgroundColor: [
					rgbaColor(getThemeColor('secondary'), 0.2),
					rgbaColor(getThemeColor('warning'), 0.2),
					rgbaColor(getThemeColor('info'), 0.2),
					rgbaColor(getThemeColor('success'), 0.2),
					rgbaColor(getThemeColor('info'), 0.2),
					rgbaColor(getThemeColor('primary'), 0.2),
				],
				borderColor: [getThemeColor('secondary'), getThemeColor('warning'), getThemeColor('info'), getThemeColor('success'), getThemeColor('info'), getThemeColor('primary')],
				borderWidth: 1,
			},
		],
	};

	const options = {
		plugins: {
			tooltip: chartJsDefaultTooltip(getThemeColor),
			legend: {
				labels: {
					color: getThemeColor('gray-500'),
				},
			},
		},
		scales: {
			x: {
				ticks: {
					color: getThemeColor('gray-500'),
				},
				grid: {
					color: getThemeColor('gray-300'),
					drawBorder: false,
				},
			},
			y: {
				ticks: {
					color: getThemeColor('gray-500'),
				},
				grid: {
					color: getThemeColor('gray-300'),
					drawBorder: false,
				},
			},
		},
	};

	return <Bar data={data} options={options} height={300} width={800} className='chartjs-responsive mb-3' />;
};

export default OrderChart;
