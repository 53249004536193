import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import SubtleBadge from '../../../../components/common/SubtleBadge';
import Message from '../../../../components/Message';
import { usePayOrdersMutation } from '../../../../slices/ordersApiSlice';

const getCircleStackIcon = (icon, transform) => (
	<span className='fa-stack ms-n1 me-3'>
		<FontAwesomeIcon icon='circle' className='text-200 fa-stack-2x' />
		<FontAwesomeIcon icon={icon} transform={transform ?? ''} className='text-primary fa-stack-1x' inverse />
	</span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
	<Flex className='mt-3'>
		{getCircleStackIcon(icon)}
		<div className='flex-1'>
			<h6>{heading}</h6>
			{children || <p className='mb-0 text-justify'>{content}</p>}
		</div>
	</Flex>
);

const PrePaymentModal = ({ setIsOpenPendingModal, isOpenPendingModal, modalPendingContent, setModalPendingContent, refetchPrePaymentOrders, isPrePaymentOrderFetching }) => {
	const { isDark } = useSelector((state) => state.config);
	const { userInfo } = useSelector((state) => state.auth);

	const [payOrder, { isLoading: loadingPay }] = usePayOrdersMutation();

	const handleClose = () => {
		setIsOpenPendingModal(!isOpenPendingModal);
		setModalPendingContent({});
	};

	const onPayOrder = async (accountsReceivable, title, orderOId, orderId) => {
		await payOrder({ orderOId, details: { payAccountsReceivable: accountsReceivable, title } });
		refetchPrePaymentOrders();
		toast.success(orderId + '订单' + title + '款项已核销!');
		handleClose();
	};

	const { _id, orderId, customerInfo, orderNotes, scheduleNodes, totalPrice, approvalStatus, paymentStatus, percentPayment, prePaymentPercent } = modalPendingContent;

	return (
		<Modal show={isOpenPendingModal} onHide={handleClose} contentClassName='border' centered size='lg'>
			<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className='bg-body-tertiary px-x1 border-bottom-0'>
				<Modal.Title>
					<Flex>
						<h5 className='mb-0'>{orderId}</h5>
						{approvalStatus === 'inProcessOfApproval' && (
							<Badge bg='danger' className='ms-2 fs-10'>
								待审批
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayPlaceOrder' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收预付款
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayDelivery' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收发货款
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayAccept' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收验收款
							</Badge>
						)}
					</Flex>

					{customerInfo?.customerName && (
						<p className='mb-0 fs-10 mt-1 fw-normal'>
							客户名称：<a href='#!'>{customerInfo.customerName}</a>
						</p>
					)}
					{isPrePaymentOrderFetching ? (
						<Spinner className='text-info' />
					) : (
						modalPendingContent?.accountsReceivables?.length !== 0 &&
						modalPendingContent?.accountsReceivables?.map((ar) => (
							<>
								<p className='mb-0 fs-10 mt-1 fw-normal'>
									订单{ar.title}应收账款 共计 {ar.accountsReceivable} 元
								</p>
							</>
						))
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='px-x1 pb-x1 pt-1 fs-10'>
				{customerInfo?.staffName && (
					<EventModalMediaContent icon='align-left' heading='客户信息'>
						<Flex justifyContent=''>
							<p className='mb-0 fs-10 mt-1 fw-normal me-3'>
								联系人：<a href='#!'>{customerInfo.staffName}</a>
							</p>
							{customerInfo?.phone && (
								<p className='mb-0 fs-10 mt-1 fw-normal'>
									电话：<a href='#!'>{customerInfo.phone}</a>
								</p>
							)}
						</Flex>
					</EventModalMediaContent>
				)}

				{totalPrice && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单总价' content={`¥${totalPrice}`} />}
				{percentPayment > 0 && (
					<>
						<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付款'>
							<span className='me-2'>¥{percentPayment}</span>
							<SubtleBadge>{prePaymentPercent}%</SubtleBadge>
						</EventModalMediaContent>
					</>
				)}

				{scheduleNodes && scheduleNodes.length !== 0 && (
					<EventModalMediaContent icon='clock' heading='尾款计划'>
						<ul className='list-unstyled timeline mb-0'>
							{scheduleNodes.map((schedule, index) => (
								<li key={index} className='mb-1'>
									<span className='me-3'>
										{schedule.title}后 {schedule.paymentDays} 天
									</span>
									<span className='me-3'>应收账款：¥{schedule.paymentAmount}</span>
									<SubtleBadge>{schedule.paymentPercent}%</SubtleBadge>
								</li>
							))}
						</ul>
					</EventModalMediaContent>
				)}
				{orderNotes && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单备注' content={orderNotes} />}
			</Modal.Body>
			{isPrePaymentOrderFetching && <Message>正在更新应收账款...</Message>}
			{userInfo.role === 'finance' && (
				<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
					<>
						{isPrePaymentOrderFetching ? (
							<Spinner className='text-info' />
						) : (
							modalPendingContent?.accountsReceivables?.map((ar) => {
								if (!ar.isPaid) {
									return (
										<p className='mb-0 fs-10 mt-1 fw-normal'>
											订单{ar.title}应收账款 共计 {ar.accountsReceivable} 元
										</p>
									);
								} else {
									return <></>;
								}
							})
						)}
						{modalPendingContent?.accountsReceivables?.map((ar) => {
							if (!ar.isPaid) {
								return (
									<Button size='sm' onClick={() => onPayOrder(ar.accountsReceivable, ar.title, modalPendingContent._id, modalPendingContent.orderId)}>
										<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
										确认已收{ar.title}款 {loadingPay && <Spinner size='sm'></Spinner>}
									</Button>
								);
							} else {
								return <></>;
							}
						})}

						<Button as={Link} to={`/order/detail/${_id}`} variant='falcon-primary' size='sm'>
							<span>查看更多细节</span>
							<FontAwesomeIcon icon='angle-right' className='fs-11 ms-1' />
						</Button>
					</>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default PrePaymentModal;
