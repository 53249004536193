import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import NotificationProvider from './providers/NotificationProvider';
import ProductProvider from './providers/ProductProvider';

import './utils/initFA';
import OrderProvider from './providers/OrderProvider';

const App = () => {
	const globalTheme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

	const { theme } = useSelector((state) => state.config);

	const mode = theme === 'auto' ? (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') : theme === 'light' ? 'light' : 'dark';

	const tableTheme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: mode, //let's use the same dark/light mode as the global theme
					primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
					info: {
						main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
					},
					background: {
						default: theme === 'auto' ? (window.matchMedia('(prefers-color-scheme: dark)').matches ? '#0b1727' : '#fff') : theme === 'light' ? '#fff' : '#0b1727',
						// theme === 'light'
						// 	? '#fff' //random light yellow color for the background in light mode
						// 	: '#0b1727', //pure black table in dark mode for fun
					},
				},
				typography: {
					button: {
						textTransform: 'none', //customize typography styles for all buttons in table by default
						fontSize: '1.2rem',
					},
				},
				components: {
					MuiTooltip: {
						styleOverrides: {
							tooltip: {
								fontSize: '1.1rem', //override to make tooltip font size larger
							},
						},
					},
					MuiSwitch: {
						styleOverrides: {
							thumb: {
								color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
							},
						},
					},
				},
			}),
		[globalTheme.palette.secondary, mode, theme]
	);
	return (
		<ThemeProvider theme={tableTheme}>
			<ProductProvider>
				<NotificationProvider>
					<OrderProvider>
						{' '}
						<Header />
						<main className='py-3'>
							<Container fluid>
								<Outlet />
							</Container>
						</main>
						<ToastContainer
							position='bottom-left'
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme='colored'
						/>
					</OrderProvider>
				</NotificationProvider>
			</ProductProvider>
		</ThemeProvider>
	);
};

export default App;
