import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faFacebook,
	faFacebookF,
	faFacebookSquare,
	faGolang,
	faGooglePlusG,
	faGulp,
	faHotjar,
	faHtml5,
	faInstagram,
	faJs,
	faLinkedin,
	faLinkedinIn,
	faMediumM,
	faNodeJs,
	faPinterest,
	faSass,
	faTrello,
	faTwitter,
	faWatchmanMonitoring,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
	faCalendarDays,
	faCommentDots,
	faCircle as farCircle,
	faClock as farClock,
	faComment as farComment,
	faCopy as farCopy,
	faDizzy as farDizzy,
	faEdit as farEdit,
	faFile as farFile,
	faFileArchive as farFileArchive,
	faFileAudio as farFileAudio,
	faFileCode as farFileCode,
	faFileImage as farFileImage,
	faFilePdf as farFilePdf,
	faFileVideo as farFileVideo,
	faHeart as farHeart,
	faImage as farImage,
	faLaughBeam as farLaughBeam,
	faLightbulb as farLightbulb,
	faObjectUngroup as farObjectUngroup,
	faPaperPlane as farPaperPlane,
	faQuestionCircle as farQuestionCircle,
	faSmileBeam as farSmileBeam,
	faSquareCheck as farSquareCheck,
	faStar as farStar,
	faWindowRestore,
} from '@fortawesome/free-regular-svg-icons';
import {
	faAdjust,
	faAlignLeft,
	faAlignRight,
	faAngleLeft,
	faAngleRight,
	faArchive,
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrowsAltV,
	faArrowsRotate,
	faArrowUp,
	faAt,
	faAward,
	faBalanceScaleLeft,
	faBan,
	faBars,
	faBarsStaggered,
	faBell,
	faBolt,
	faBook,
	faBookOpen,
	faBoxOpen,
	faBrush,
	faBug,
	faCalendarAlt,
	faCalendarCheck,
	faCalendarDay,
	faCamera,
	faCameraRetro,
	faCapsules,
	faCaretDown,
	faCaretRight,
	faCaretUp,
	faCartPlus,
	faChalkboardTeacher,
	faChartLine,
	faChartPie,
	faCheck,
	faCheckCircle,
	faCheckDouble,
	faCheckSquare,
	faChessRook,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCircleExclamation,
	faCircleNotch,
	faClipboard,
	faClipboardCheck,
	faClipboardQuestion,
	faClock,
	faClockRotateLeft,
	faClosedCaptioning,
	faCloudDownloadAlt,
	faCode,
	faCodeBranch,
	faCog,
	faCoins,
	faComment,
	faCommentDollar,
	faComments,
	faCopy,
	faCross,
	faCrown,
	faCubes,
	faDeleteLeft,
	faDollarSign,
	faDownload,
	faEdit,
	faEllipsisH,
	faEllipsisV,
	faEnvelope,
	faEnvelopeOpen,
	faEnvelopeOpenText,
	faExchangeAlt,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faExpandArrowsAlt,
	faExternalLinkAlt,
	faEye,
	faFeather,
	faFile,
	faFileAlt,
	faFileArchive,
	faFileContract,
	faFileDownload,
	faFileImport,
	faFileInvoiceDollar,
	faFilePdf,
	faFileUpload,
	faFilter,
	faFire,
	faFireFlameCurved,
	faFlag,
	faFloppyDisk,
	faFolderPlus,
	faGift,
	faGlobe,
	faGlobeAmericas,
	faGraduationCap,
	faGripLines,
	faHandHoldingDollar,
	faHandHoldingWater,
	faHandshake,
	faHandshakeAngle,
	faHashtag,
	faHeadphones,
	faHeart,
	faHome,
	faHourglassStart,
	faIcons,
	faImage,
	faInfinity,
	faInfo,
	faInfoCircle,
	faKey,
	faLayerGroup,
	faLink,
	faList,
	faListAlt,
	faListUl,
	faLocationArrow,
	faLock,
	faLongArrowAltDown,
	faLongArrowAltUp,
	faMap,
	faMapMarkerAlt,
	faMapPin,
	faMinus,
	faMobile,
	faMoneyCheck,
	faMoon,
	faMountainSun,
	faMusic,
	faObjectUngroup,
	faPalette,
	faPaperclip,
	faPaperPlane,
	faPencilAlt,
	faPenNib,
	faPeopleCarryBox,
	faPercentage,
	faPhone,
	faPhoneAlt,
	faPlaneArrival,
	faPlay,
	faPlayCircle,
	faPlug,
	faPlus,
	faPlusSquare,
	faPoll,
	faPrint,
	faProjectDiagram,
	faPuzzlePiece,
	faQuestionCircle,
	faQuoteLeft,
	faQuoteRight,
	faRedo,
	faRedoAlt,
	faReply,
	faRocket,
	faRotateRight,
	faSackDollar,
	faSearch,
	faShapes,
	faShare,
	faShareAlt,
	faShoppingCart,
	faSignInAlt,
	faSignOutAlt,
	faSlidersH,
	faSmileBeam,
	faSort,
	faSortAlphaDown,
	faSortAlphaUp,
	faSortAmountDown,
	faSortAmountUp,
	faSortNumericDown,
	faSortNumericUp,
	faSpinner,
	faStamp,
	faStar,
	faStarHalfAlt,
	faStickyNote,
	faStream,
	faSun,
	faSyncAlt,
	faTable,
	faTag,
	faTags,
	faTasks,
	faTh,
	faThumbsUp,
	faThumbtack,
	faTicketAlt,
	faTimes,
	faTimesCircle,
	faTint,
	faTools,
	faTrash,
	faTrashAlt,
	faTriangleExclamation,
	faTrophy,
	faTruck,
	faTruckFast,
	faTruckPlane,
	faTruckRampBox,
	faUnlockAlt,
	faUpload,
	faUser,
	faUserCircle,
	faUserClock,
	faUserFriends,
	faUserGraduate,
	faUserPlus,
	faUsers,
	faUserTie,
	faUtensils,
	faVideo,
	faWrench,
	faYenSign,
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faHandshake,
	faMoneyCheck,
	faClockRotateLeft,
	faFolderPlus,
	faFloppyDisk,
	faRotateRight,
	faGolang,
	faDeleteLeft,
	faBars,
	faArrowsRotate,
	faUserTie,
	faUpload,
	faTruckFast,
	faBoxOpen,
	faThumbsUp,
	faYenSign,
	faCoins,
	faUserClock,
	faPeopleCarryBox,
	faClipboardQuestion,
	faWatchmanMonitoring,
	faHandshakeAngle,
	faBarsStaggered,
	faCubes,
	faClipboardCheck,
	faStamp,
	faStickyNote,
	faCommentDots,
	faQuoteLeft,
	faQuoteRight,
	faFeather,
	faHourglassStart,
	faMountainSun,
	faFireFlameCurved,
	faPlaneArrival,
	faTruckPlane,
	faTruckRampBox,
	faHandHoldingDollar,
	faSackDollar,
	faFileContract,
	faPaperPlane,
	faSpinner,
	faAdjust,
	faExclamation,
	faCircleExclamation,
	faTriangleExclamation,
	faWindowRestore,
	faCalendarDays,
	faCalendarDay,
	// Solid
	faClipboard,
	faEnvelopeOpenText,
	faFileDownload,
	faObjectUngroup,
	faPhoneAlt,
	faKey,
	faFileImport,
	faTicketAlt,
	faChartPie,
	faCopy,
	faChessRook,
	faSearch,
	faHome,
	faBell,
	faPlay,
	faChevronRight,
	faChevronLeft,
	faChevronUp,
	faChevronDown,
	faExchangeAlt,
	faAngleRight,
	faAngleLeft,
	faEllipsisH,
	faCrown,
	faListAlt,
	faCheck,
	faBan,
	faStream,
	faArrowsAltV,
	faSort,
	faSortAlphaDown,
	faSortAlphaUp,
	faSortNumericDown,
	faSortNumericUp,
	faSortAmountDown,
	faSortAmountUp,
	faCheckCircle,
	faUserCircle,
	faQuestionCircle,
	faLock,
	faCircle,
	faHeart,
	faShareAlt,
	faCaretRight,
	faArrowDown,
	faArrowUp,
	faArrowLeft,
	faArrowRight,
	faPrint,
	faDollarSign,
	faLink,
	faPlus,
	faMinus,
	faUser,
	faPencilAlt,
	faCamera,
	faTimesCircle,
	faSignInAlt,
	faBook,
	faPuzzlePiece,
	faTrash,
	faAlignLeft,
	faAlignRight,
	faPlug,
	faTimes,
	faFilter,
	faExternalLinkAlt,
	faCartPlus,
	faShoppingCart,
	faStar,
	faStarHalfAlt,
	faArchive,
	faEnvelope,
	faClock,
	faStar,
	faReply,
	faLocationArrow,
	faCog,
	faPaperclip,
	faImage,
	faEllipsisV,
	faEnvelopeOpen,
	faRedo,
	faSlidersH,
	faTrashAlt,
	faFileArchive,
	faFilePdf,
	faFileAlt,
	faEye,
	faCaretUp,
	faCodeBranch,
	faExclamationTriangle,
	faListUl,
	faTh,
	faTruck,
	faGift,
	faUsers,
	faGlobeAmericas,
	faGlobe,
	faPlusSquare,
	faCaretDown,
	faUserPlus,
	faCircleNotch,
	faExclamation,
	faThumbsUp,
	faComments,
	faPalette,
	faYenSign,
	faEdit,
	faTrashAlt,
	faShare,
	faCheckSquare,
	faTools,
	faCheckDouble,
	faSmileBeam,
	faPoll,
	faPercentage,
	faTags,
	faIcons,
	faList,
	faUserFriends,
	faExclamationCircle,
	faFolderPlus,
	faTag,
	faAt,
	faCalendarAlt,
	faCalendarCheck,
	faCalendarDay,
	faMapMarkerAlt,
	faCross,
	faFlag,
	faThumbtack,
	faTable,
	faChartLine,
	faShapes,
	faMap,
	faFire,
	faLayerGroup,
	faRocket,
	faWrench,
	faSun,
	faMoon,
	faInfoCircle,
	faSpinner,
	faCapsules,
	faTasks,
	faCloudDownloadAlt,
	faSyncAlt,
	faBug,
	faProjectDiagram,
	faBolt,
	faPaperPlane,
	faLongArrowAltDown,
	faLongArrowAltUp,
	faInfinity,
	faFile,
	faMusic,
	faMobile,
	faBookOpen,
	faTint,

	// Brand
	faFacebook,
	faFacebookF,
	faFacebookSquare,
	faLinkedin,
	faInstagram,
	faPinterest,
	faGulp,
	faNodeJs,
	faSass,
	faTwitter,
	faGooglePlusG,
	faLinkedinIn,
	faMediumM,
	faHotjar,
	faYoutube,
	faVideo,
	faInfo,
	faPhone,
	faTrello,
	faCode,
	faJs,
	faHtml5,
	faTrophy,

	// Regular
	farHeart,
	farLightbulb,
	farObjectUngroup,
	farPaperPlane,
	farStar,
	farImage,
	farFileArchive,
	farFilePdf,
	farFile,
	farFileVideo,
	farFileAudio,
	farFileImage,
	farFileCode,
	farClock,
	farQuestionCircle,
	farDizzy,
	farLaughBeam,
	farSmileBeam,
	farCircle,
	farCopy,
	farComment,
	farEdit,
	farSquareCheck,
	faRedoAlt,
	faUserGraduate,
	faChalkboardTeacher,
	faGraduationCap,
	faSignOutAlt,
	faDownload,
	faFileUpload,
	faExpandArrowsAlt,
	faUnlockAlt,
	faGripLines,
	faComment,
	faHeadphones,
	faClosedCaptioning,
	faMapPin,
	faPlayCircle,
	faPenNib,
	faAward,
	faBrush,
	faHashtag,
	faFileInvoiceDollar,
	faBalanceScaleLeft,
	faCommentDollar,
	faHandHoldingWater,
	faCameraRetro,
	faUtensils
);
