export const BASE_URL = '';
export const STATISTIC_URL = '/api/statistics';
export const DASHBOARD_URL = '/api/dashboard';
export const PRODUCTS_URL = '/api/products';
export const CUSTOMERS_URL = '/api/customers';
export const USERS_URL = '/api/users';
export const ORDERS_URL = '/api/orders';
export const ORDER_DRAFTS_URL = '/api/drafts';
export const APPROVALS_URL = '/api/approvals';
export const NOTIFICATIONS_URL = '/api/notifications';
export const PAYPAL_URL = '/api/config/paypal';
export const UPLOAD_URL = '/api/upload';
