import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Badge, Card, Nav, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SimpleBarReact from 'simplebar-react';
import Flex from '../../../components/common/Flex';
import {
	useGetAcceptingOrdersQuery,
	useGetAccountsReceivableOrdersQuery,
	useGetApplingOrdersQuery,
	useGetApprovingOrdersQuery,
	useGetContractOrdersQuery,
	useGetDeliveringOrdersQuery,
	useGetPrePaymentOrdersQuery,
} from '../../../slices/dashboardApiSlice';
import AcceptingOrderPendings from './AcceptingOrderPendings';
import AccountsReceivableOrderPendings from './AccountsReceivableOrderPendings';
import ApplingOrderPendings from './ApplingOrderPendings';
import ApprovingOrderPendings from './ApprovingOrderPendings';
import ContractOrderPendings from './ContractOrderPendings';
import DeliveringOrderPendings from './DeliveringOrderPendings';
import PrePaymentOrderPendings from './PrePaymentOrderPendings';
import RecentOrderPendings from './RecentOrderPendings';
import { useOrderContext } from '../../../providers/OrderProvider';

const TabTitle = ({ title, subTitle, isPaid, icon, notification_number, bg }) => (
	<Flex className='p-3 ps-2 text-start cursor-pointer gap-1'>
		<Flex className='flex-column flex-between-center '>
			{/* {isPaid && <FontAwesomeIcon icon='crown' className='fs-11 text-warning' transform='shrink-4' />} */}

			{notification_number === 0 ? (
				<></>
			) : notification_number !== 0 && notification_number >= 10 ? (
				<div className='position-relative shrink-4 fs-11'>
					<Badge bg='danger' pill className='position-absolute' style={{ right: '0.08rem', top: '0.525rem' }}>
						<span className='fw-bolder fs-11'>{notification_number}</span>
					</Badge>
				</div>
			) : (
				<div className='notification-indicator notification-indicator-danger position-relative notification-indicator-fill shrink-4'>
					<span className='fs-11 notification-indicator-number'>{notification_number}</span>
				</div>
			)}

			<FontAwesomeIcon icon={icon} className='fs-7 mt-auto' />
		</Flex>
		<div className='ms-2'>
			<h6 className='text-700 fs-11 text-nowrap mb-1'>{title}</h6>
			<h5 className='mb-0 lh-1 fs-9'>{subTitle}</h5>
		</div>
	</Flex>
);

const ProcessingOrderCard = () => {
	const { userInfo } = useSelector((state) => state.auth);

	const { orderPendingForFinance, orderPendingForBuyer } = useOrderContext();

	const [skipLoadApproval] = useState(true);
	const [skipLoadDelivery] = useState(true);
	const [skipLoadAccept] = useState(true);
	const [skipLoadAR] = useState(true);
	const [skipLoadApply] = useState(true);
	const [skipLoadPrePayment] = useState(true);
	const [skipLoadContract] = useState(true);

	const {
		data: myApprovals = [],
		isLoading: isLoadingMyApprovals,
		isError: isLoadingMyApprovalsError,
		refetch: refetchMyApprovals,
		isUninitialized: isApprovalsUninitialized,
	} = useGetApprovingOrdersQuery(undefined, {
		skip: userInfo.role === 'manager' ? !skipLoadApproval : skipLoadApproval,
	});

	// get approving orders hook
	const {
		data: deliveringOrders = [],
		isLoading: isLoadingDeliveringOrder,
		isError: isLoadingDeliveringOrderError,
		refetch: refetchDeliveringOrders,
		isFetching: isDeliveringOrderFetching,
		isUninitialized: isDeliveringOrdersUninitialized,
	} = useGetDeliveringOrdersQuery(undefined, {
		skip: ['manager', 'sale', 'assistant'].includes(userInfo.role) ? !skipLoadDelivery : skipLoadDelivery,
	});

	// get approving orders hook
	const {
		data: acceptingOrders = [],
		isLoading: isLoadingAcceptingOrder,
		isError: isLoadingAcceptingOrderError,
		refetch: refetchAcceptingOrders,
		isFetching: isAcceptingOrderFetching,
		isUninitialized: isAcceptingOrdersUninitialized,
	} = useGetAcceptingOrdersQuery(undefined, {
		skip: ['manager', 'sale', 'assistant'].includes(userInfo.role) ? !skipLoadAccept : skipLoadAccept,
	});

	// get contract orders hook
	const {
		data: arOrders = [],
		isLoading: isLoadingAROrders,
		isError: isLoadingAROrdersError,
		refetch: refetchAROrders,
		isFetching: isAROrderFetching,
		isUninitialized: isArOrdersUninitialized,
	} = useGetAccountsReceivableOrdersQuery(undefined, {
		skip: ['manager', 'finance', 'sale', 'assistant'].includes(userInfo.role) ? !skipLoadAR : skipLoadAR,
	});

	// get appling orders hook
	const {
		data: applingOrders = [],
		isLoading: isLoadingApplingOrders,
		isError: isLoadingApplingOrdersError,
		refetch: refetchApplingOrders,
		isUninitialized: isApplingOrdersUninitialized,
	} = useGetApplingOrdersQuery(undefined, {
		skip: ['sale', 'assistant'].includes(userInfo.role) ? !skipLoadApply : skipLoadApply,
	});

	// get approving orders hook
	const {
		data: prePaymentOrders = [],
		isLoading: isLoadingPrePaymentOrder,
		isError: isLoadingPrePaymentOrderError,
		refetch: refetchPrePaymentOrders,
		isUninitialized: isPrePaymentOrdersUninitialized,
	} = useGetPrePaymentOrdersQuery(undefined, {
		skip: ['finance'].includes(userInfo.role) ? !skipLoadPrePayment : skipLoadPrePayment,
	});

	// get contract orders hook
	const {
		data: contractOrders = [],
		isLoading: isLoadingContractOrders,
		isError: isLoadingContractOrdersError,
		refetch: refetchContractOrders,
		isFetching: isFetchingContractOrders,
		isUninitialized: isContractOrdersUninitialized,
	} = useGetContractOrdersQuery(undefined, {
		skip: userInfo.role === 'buyer' ? !skipLoadContract : skipLoadContract,
	});

	useEffect(() => {
		if (!isDeliveringOrdersUninitialized) {
			refetchDeliveringOrders();
		}
		if (!isApprovalsUninitialized) {
			refetchMyApprovals();
		}
		if (!isAcceptingOrdersUninitialized) {
			refetchAcceptingOrders();
		}
		if (!isArOrdersUninitialized) {
			refetchAROrders();
		}
		if (!isContractOrdersUninitialized) {
			refetchContractOrders();
		}
		if (!isApplingOrdersUninitialized) {
			refetchApplingOrders();
		}
		if (!isPrePaymentOrdersUninitialized) {
			refetchPrePaymentOrders();
		}
	}, [
		isAcceptingOrdersUninitialized,
		isApplingOrdersUninitialized,
		isApprovalsUninitialized,
		isArOrdersUninitialized,
		isContractOrdersUninitialized,
		isDeliveringOrdersUninitialized,
		isPrePaymentOrdersUninitialized,
		refetchAROrders,
		refetchAcceptingOrders,
		refetchApplingOrders,
		refetchContractOrders,
		refetchDeliveringOrders,
		refetchMyApprovals,
		refetchPrePaymentOrders,
	]);

	return (
		<Card className='mb-3'>
			{userInfo.role === 'manager' && (
				<Tab.Container id='top-course-tab' defaultActiveKey='approvalPending'>
					<SimpleBarReact>
						<Card.Header className='p-0'>
							<Nav className='nav-tabs top-courses-tab border-0 flex-nowrap'>
								<>
									<Nav.Item>
										<Nav.Link className='mb-0' eventKey='approvalPending'>
											<TabTitle title='Approving' subTitle='待审批订单' isPaid={true} isActive={true} icon='feather' notification_number={myApprovals.length} bg='danger' />
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='mb-0' eventKey='deliveryPending'>
											<TabTitle
												title='Delivering'
												subTitle='待发货订单'
												isPaid={true}
												isActive={false}
												icon='fa-truck-plane'
												notification_number={deliveringOrders.length}
												bg='primary'
											/>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='mb-0' eventKey='acceptPending'>
											<TabTitle
												title='Accepting'
												subTitle='待验收订单'
												isPaid={true}
												isActive={false}
												icon='fa-truck-ramp-box'
												notification_number={acceptingOrders.length}
												bg='info'
											/>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='mb-0' eventKey='accountsReceivablePending'>
											<TabTitle title='AccountsReceivable' subTitle='应收账款' isPaid={true} isActive={false} icon='fa-fire-flame-curved' notification_number={arOrders.length} />
										</Nav.Link>
									</Nav.Item>
								</>
							</Nav>
						</Card.Header>
					</SimpleBarReact>

					<Card.Body className='p-0'>
						<Tab.Content>
							<Tab.Pane unmountOnExit eventKey='approvalPending'>
								<ApprovingOrderPendings
									data={myApprovals}
									isLoading={isLoadingMyApprovals}
									isError={isLoadingMyApprovalsError}
									refetchMyApprovals={refetchMyApprovals}
									refetchAROrders={refetchAROrders}
								/>
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='deliveryPending'>
								<DeliveringOrderPendings
									data={deliveringOrders}
									isLoading={isLoadingDeliveringOrder}
									isError={isLoadingDeliveringOrderError}
									refetch={refetchDeliveringOrders}
									isFetching={isDeliveringOrderFetching}
								/>
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='acceptPending'>
								<AcceptingOrderPendings
									data={acceptingOrders}
									isLoading={isLoadingAcceptingOrder}
									isError={isLoadingAcceptingOrderError}
									refetch={refetchAcceptingOrders}
									isFetching={isAcceptingOrderFetching}
									refetchAROrders={refetchAROrders}
								/>
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='accountsReceivablePending'>
								<AccountsReceivableOrderPendings
									data={arOrders}
									isLoading={isLoadingAROrders}
									isError={isLoadingAROrdersError}
									refetch={refetchAROrders}
									isFetching={isAROrderFetching}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Card.Body>
				</Tab.Container>
			)}
			{userInfo.role === 'finance' && (
				<Tab.Container id='top-course-tab' defaultActiveKey='prePaymentOrder'>
					<SimpleBarReact>
						<Card.Header className='p-0'>
							<Nav className='nav-tabs top-courses-tab border-0 flex-nowrap'>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='prePaymentOrder'>
										<TabTitle title='PrePay' subTitle='预付款确认' isPaid={true} isActive={true} icon='fa-sack-dollar' notification_number={prePaymentOrders.length} />
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='ProcessingOrder'>
										<TabTitle title='Processing' subTitle='进程订单' isPaid={true} isActive={false} icon='fa-mountain-sun' notification_number={orderPendingForFinance.length} />
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='accountsReceivablePendingFinance'>
										<TabTitle
											title='AccountsReceivable'
											subTitle='应收账款'
											isPaid={true}
											isActive={false}
											icon='fa-fire-flame-curved'
											notification_number={userInfo.role === 'finance' ? arOrders.filter((x) => x.paymentStatus !== 'waitingPayPlaceOrder').length : arOrders.length}
										/>
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
					</SimpleBarReact>

					<Card.Body className='p-0'>
						<Tab.Content>
							<Tab.Pane unmountOnExit eventKey='prePaymentOrder'>
								<PrePaymentOrderPendings data={prePaymentOrders} isLoading={isLoadingPrePaymentOrder} isError={isLoadingPrePaymentOrderError} refetch={refetchPrePaymentOrders} />
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='ProcessingOrder'>
								<RecentOrderPendings />
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='accountsReceivablePendingFinance'>
								<AccountsReceivableOrderPendings
									data={userInfo.role === 'finance' ? arOrders.filter((x) => x.paymentStatus !== 'waitingPayPlaceOrder') : arOrders}
									isLoading={isLoadingAROrders}
									isError={isLoadingAROrdersError}
									refetch={refetchAROrders}
									isFetching={isAROrderFetching}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Card.Body>
				</Tab.Container>
			)}
			{userInfo.role === 'buyer' && (
				<Tab.Container id='top-course-tab' defaultActiveKey='ContractPending'>
					<SimpleBarReact>
						<Card.Header className='p-0'>
							<Nav className='nav-tabs top-courses-tab border-0 flex-nowrap'>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='ContractPending'>
										<TabTitle title='Contract' subTitle='待签订单' isPaid={true} isActive={true} icon='fa-file-contract' notification_number={contractOrders.length} />
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='ProcessingOrderBuyer'>
										<TabTitle title='Processing' subTitle='进程订单' isPaid={true} isActive={false} icon='fa-mountain-sun' notification_number={orderPendingForBuyer.length} />
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
					</SimpleBarReact>

					<Card.Body className='p-0'>
						<Tab.Content>
							<Tab.Pane unmountOnExit eventKey='ContractPending'>
								<ContractOrderPendings
									data={contractOrders}
									isLoading={isLoadingContractOrders}
									isError={isLoadingContractOrdersError}
									refetch={refetchContractOrders}
									isFetching={isFetchingContractOrders}
								/>
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='ProcessingOrderBuyer'>
								<RecentOrderPendings />
							</Tab.Pane>
						</Tab.Content>
					</Card.Body>
				</Tab.Container>
			)}
			{['sale', 'assistant'].includes(userInfo.role) && (
				<Tab.Container id='top-course-tab' defaultActiveKey='Appling'>
					<SimpleBarReact>
						<Card.Header className='p-0'>
							<Nav className='nav-tabs top-courses-tab border-0 flex-nowrap'>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='Appling'>
										<TabTitle title='Appling' subTitle='订单申请中' isPaid={true} isActive={true} icon='fa-paper-plane' notification_number={applingOrders.length} />
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='deliveryPending'>
										<TabTitle title='Delivery' subTitle='待发货订单' isPaid={true} isActive={false} icon='fa-truck-plane' notification_number={deliveringOrders.length} />
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='acceptPending'>
										<TabTitle title='Accept' subTitle='待验收订单' isPaid={true} isActive={false} icon='fa-truck-ramp-box' notification_number={acceptingOrders.length} />
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link className='mb-0' eventKey='accountsReceivablePendingFinance'>
										<TabTitle title='AccountsReceivable' subTitle='应收账款' isPaid={true} isActive={false} icon='fa-fire-flame-curved' notification_number={arOrders.length} />
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
					</SimpleBarReact>

					<Card.Body className='p-0'>
						<Tab.Content>
							<Tab.Pane unmountOnExit eventKey='Appling'>
								<ApplingOrderPendings data={applingOrders} isLoading={isLoadingApplingOrders} isError={isLoadingApplingOrdersError} />
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='deliveryPending'>
								<DeliveringOrderPendings
									data={deliveringOrders}
									isLoading={isLoadingDeliveringOrder}
									isError={isLoadingDeliveringOrderError}
									refetch={refetchDeliveringOrders}
									isFetching={isDeliveringOrderFetching}
								/>
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='acceptPending'>
								<AcceptingOrderPendings
									data={acceptingOrders}
									isLoading={isLoadingAcceptingOrder}
									isError={isLoadingAcceptingOrderError}
									refetch={refetchAcceptingOrders}
									isFetching={isAcceptingOrderFetching}
									refetchAROrders={refetchAROrders}
								/>
							</Tab.Pane>
							<Tab.Pane unmountOnExit eventKey='accountsReceivablePendingFinance'>
								<AccountsReceivableOrderPendings
									data={userInfo.role === 'finance' ? arOrders.filter((x) => x.paymentStatus === 'waitingPayPlaceOrder') : arOrders}
									isLoading={isLoadingAROrders}
									isError={isLoadingAROrdersError}
									refetch={refetchAROrders}
									isFetching={isAROrderFetching}
								/>
							</Tab.Pane>
						</Tab.Content>
					</Card.Body>
				</Tab.Container>
			)}
		</Card>
	);
};

export default ProcessingOrderCard;
